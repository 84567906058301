import { createApi } from '@reduxjs/toolkit/dist/query/react'
import baseQueryWithIam from '../../app/baseQueryWithIam'
import {NewsState} from "../types";

export const newsApiSlice = createApi({
    reducerPath: 'newsApi',
    baseQuery: baseQueryWithIam,
    tagTypes: ['News'],
    endpoints: (build) => ({
        fetchNews: build.query<NewsState, void>({
            query: () => ({
                url: `/v1/news`,
            }),
            transformResponse: (response: NewsState) => response
        }),
    }),
})
