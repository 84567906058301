import React from "react";
import styled from "@emotion/styled";
import WineShelf from "../../../assets/img/Landing/Intro/wine-shelf.png"
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";

const Investment = () => {

    const navigate = useNavigate()
    const {formatMessage} = useIntl()

    return (
        <IntroWrapper id="contact">
            <FlexBox>
                <StyledImg src={WineShelf}/>
                <Content>
                    {/*<Typography*/}
                    {/*    variant="caption"*/}
                    {/*    component="div"*/}
                    {/*    color="text.secondary"*/}
                    {/*    sx={{*/}
                    {/*        fontWeight: 300,*/}
                    {/*        fontSize: '16px',*/}
                    {/*        color: '#F9F2DC',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {formatMessage({id: "investmentSubtitleTitle"})}*/}
                    {/*</Typography>*/}
                    <Typography
                        variant="h1"
                        component="div"
                        color="text.secondary"
                        sx={{
                            fontWeight: 700,
                            fontSize: '40px',
                            color: '#FFFFFF',
                            mb: '32px'
                        }}
                    >
                        {formatMessage({id: "investmentTitle"})}
                    </Typography>
                    <Typography
                        variant="h1"
                        component="div"
                        color="text.secondary"
                        sx={{
                            fontWeight: 300,
                            fontSize: '20px',
                            color: '#B8BACE',
                            mb: '50px',
                            lineHeight: 1.4
                        }}
                    >
                        {formatMessage({id: "investmentContentText"})}
                    </Typography>
                    <Button variant={"outlined"} color={"info"} size={"large"}
                            onClick={() => navigate('/sign-up')}>{formatMessage({id: "registration"})}</Button>

                </Content>
            </FlexBox>

        </IntroWrapper>
    )
}

export default Investment

const IntroWrapper = styled.div`
  width: 100%;
  min-width: 100vh;
  max-height: 90vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  background-color: #23242D;
  overflow-y: hidden;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: start;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  margin-left: 80px;
  width: 650px;
`

const StyledImg = styled.img`
  width: 639px;
`