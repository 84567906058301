import React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, IconButton, Typography,
} from '@mui/material'
import styled from "@emotion/styled";
import {X} from "@phosphor-icons/react";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";

const InvestAndSellDialog = ({open, handleClose, title, content}) => {
    const {formatMessage} = useIntl()
    const navigate = useNavigate()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
        >
            <DialogTitle sx={{backgroundColor: '#FAFAFA'}}>
                <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '28px',
                        color: '#4A4A4A',
                        textAlign: 'center',
                        m: "44px auto 12px"
                    }}
                >{title}</Typography>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <X size={24} color="#525252"/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{backgroundColor: '#FAFAFA', p: "0 60px"}}>
                <Typography
                    component="div"
                    sx={{
                        fontWeight: 300,
                        fontSize: '22px',
                        color: '#000000',
                        mb: 5,
                        lineHeight: '39px'
                    }}
                >
                    {content}
                </Typography>
                <FlexBox>
                    <StyledButton sx={{mb: 5}} color="secondary" variant="contained" fullWidth
                                   size="large" onClick={handleClose}>
                        Ok
                    </StyledButton>

                    <StyledButton
                        sx={{ mb: 5 }}
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={() => navigate('/support')}
                        size="large"
                    >
                        {formatMessage({ id: 'contactManager' })}
                    </StyledButton>
                </FlexBox>
            </DialogContent>
        </Dialog>
    )
}

export default InvestAndSellDialog

const StyledButton = styled(Button)`
  background-color: #292D34;
  color: #FFF;
  text-transform: none;
  padding: 0 !important;
`
const FlexBox = styled.div`
  display: flex;
  gap: 30px;
`