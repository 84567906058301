import { createApi } from '@reduxjs/toolkit/dist/query/react'
import baseQueryWithIam from '../../app/baseQueryWithIam'
import {MyPortfolioState} from "../types";

export const myPortfolioApiSlice = createApi({
    reducerPath: 'myPortfolioApi',
    baseQuery: baseQueryWithIam,
    tagTypes: ['MyPortfolio'],
    endpoints: (build) => ({
        fetchPortfolio: build.query<MyPortfolioState, void>({
            query: () => ({
                url: `/v1/myPortfolio`,
            }),
            transformResponse: (response: MyPortfolioState) => response
        })
    }),
})
