import React, {useState} from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, IconButton, Typography,
} from '@mui/material'
import styled from "@emotion/styled";
import {X} from "@phosphor-icons/react";
import {faqsApiSlice} from "../../../../features/faqs/faqsSlice";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";
import SuccessRequestACallDialog from "./SuccessRequestACallDialog";

const RequestACallDialog = ({open, handleClose}) => {
    const {formatMessage} = useIntl()
    const [skipCall, setSkipCall] = useState(true)
    const [openDialog, setOpenDialog] = useState(false)
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const { data: requestACallResponse } = faqsApiSlice.useRequestACallQuery({},{ skip: skipCall });

    const handleRequestACall = async () => {
        setSkipCall(false);
        try {
            await requestACallResponse;
            toast.success(formatMessage({ id: 'callHaveBeenRequested' }));
            handleOpenDialog()
        } catch (error) {
            toast.error(formatMessage({ id: 'requestACallError' }));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
        >
            <DialogTitle sx={{backgroundColor: '#FAFAFA'}}>
                <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '28px',
                        color: '#4A4A4A',
                        textAlign: 'center',
                        m: "44px auto 12px"
                    }}
                >Do u want to request a call from<br/> your personal manager?</Typography>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <X size={24} color="#525252"/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{backgroundColor: '#FAFAFA', p: "0 60px"}}>
                <Typography
                    component="div"
                    sx={{
                        fontWeight: 300,
                        fontSize: '20px',
                        color: '#000000',
                        mb: 7
                    }}
                >
                    If you need a call our manager will contact you as soon as possible!
                </Typography>
                <FlexBox>

                    <StyledButton sx={{mb: 6}} color="secondary" variant="contained" fullWidth
                                   size="large" onClick={handleRequestACall}>
                        Request a call
                    </StyledButton>

                    <StyledButton sx={{mb: 6}} color="secondary" variant="contained" fullWidth onClick={handleClose}
                                  size="large">
                        No, I dont need
                    </StyledButton>
                </FlexBox>

                <SuccessRequestACallDialog
                    open={openDialog}
                    handleClose={handleClose}
                />


            </DialogContent>
        </Dialog>
    )
}

export default RequestACallDialog

const StyledButton = styled(Button)`
  background-color: #292D34;
  color: #FFF;
  text-transform: none;
`
const FlexBox = styled.div`
  display: flex;
  gap: 30px;
`