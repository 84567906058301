import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { REACT_APP_IAM_SERVICE_BASE_URL } from '../../utils/constants'
import storage from '../../utils/storageService'

export const userLogin = createAsyncThunk(
    'user/login',
    async ({ email, password }: any, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'device-id': "werwer"
                },
            }

            const { data } = await axios.post(
                REACT_APP_IAM_SERVICE_BASE_URL + '/v1/login',
                { email, password },
                config,
            )
            // store user's token in local storage
            storage.set('access', data.access)
            storage.set('refresh', data.refresh)
            return data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    },
)
