import React from 'react'
import * as yup from 'yup'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, IconButton, Typography,
} from '@mui/material'
import styled from "@emotion/styled";
import {X} from "@phosphor-icons/react";
import {faqsApiSlice} from "../../../../features/faqs/faqsSlice";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {requiredString} from "../../../../utils/validation";
import {useIntl} from "react-intl";
import CustomInput from "../../../../components/muiComponents/customInput";

export interface MessageState {
    message: string
}

const SupportDialog = ({open, handleClose}) => {
    const {formatMessage} = useIntl()

    const [sendMessage] = faqsApiSlice.useLeaveMessageMutation()

    const message: MessageState = {
        message: ''
    }

    const validationSchema = yup.object().shape({
        message: requiredString({formatMessage}),
    })

    const handleCreateStudent = async () => {
        try {
            await sendMessage({
                ...formik.values,
            }).unwrap()
            toast.success(formatMessage({id: 'messageSentToYourManager'}))
            handleClose()
        } catch (error) {
            toast.error((formatMessage({id: 'errorInSendingRequest'})))
        }
    };

    const formik = useFormik({
        initialValues: message,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async () => {
            handleCreateStudent()
        },
    })
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
        >
            <DialogTitle sx={{ backgroundColor: '#FAFAFA' }}>
                <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '28px',
                        color: '#4A4A4A',
                        textAlign: 'center',
                        m: "44px auto 28px"
                    }}
                >Leave a message to your manager</Typography>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <X size={24} color="#525252"/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ backgroundColor: '#FAFAFA', p: "0 60px" }}>

                <CustomInput
                    name="message"  // Corrected the typo here
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.message}
                    error={Boolean(formik.errors.message)}
                    color="secondary"
                    multiline
                    fullWidth
                    placeholder="Write your message here"
                    sx={{
                        mb: '36px',
                        '& .MuiInputBase-root': {
                            minHeight: '250px',
                            alignItems: 'start',
                            backgroundColor: '#fff',
                            minWidth: '450px',
                            border: '1px solid rgba(199, 199, 199, 1)',
                            borderRadius: '8px'
                        }, '& .MuiOutlinedInput-root': {
                            minHeight: '250px',
                            alignItems: 'start',
                            backgroundColor: '#fff',
                            minWidth: '450px',
                            border: '1px solid rgba(199, 199, 199, 1)',
                            borderRadius: '8px'
                        },
                    }}
                    size="medium"
                />

                <StyledButton color="secondary" variant="contained" fullWidth onClick={() => formik.handleSubmit()} size="large">
                    {formatMessage({id: "leaveAMessage"})}
                </StyledButton>

                <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: '#525252',
                        textAlign: 'center',
                        m: "20px 0px"
                    }}
                >Your manager will reply to your message as soon as possible</Typography>

            </DialogContent>
        </Dialog>
    )
}

export default SupportDialog

const StyledButton = styled(Button)`
  background-color: #292D34;
  color: #FFF;
  
`