import React, {useEffect, useState} from 'react'
import * as yup from 'yup'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, IconButton, Typography,
} from '@mui/material'
import styled from "@emotion/styled";
import {X} from "@phosphor-icons/react";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";
import {myBottlesApiSlice} from "../../../../features/myBottles/myBottlesSlice";
import {InvestmentBuyOrSaleState} from "../../../../features/types";

export interface MessageState {
    message: string
}

interface FlexProps {
    direction?: string,
    gap?: string,
    align?: string,
}

interface FontColorProps {
    fontSize?: number;
    fontWeight?: number;
}

const BuyInvestmentsDialog = ({open, handleClose, data}) => {
    const {formatMessage} = useIntl()

    const [buyInvestment] = myBottlesApiSlice.useBuyInvestmentsMutation()

    const investment: InvestmentBuyOrSaleState = {
        investment: {
            id: {
                type: "BottleId",
                id: ""
            },
            amount: 1,
        },
    }


    const formik = useFormik({
        initialValues: investment,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async () => {
            formik.setValues({
                ...formik.values,
                investment: {
                    ...formik.values.investment,
                    id: {
                        type: "BottleId",
                        id: data?.id,
                    },
                },
            });

            formik.values.investment.id.id = data?.id;

            try {
                await buyInvestment(formik.values).unwrap();
                toast.success(formatMessage({id: 'sendSuccess'}));
            } catch (error) {
                toast.error(formatMessage({id: error?.data}));
            }
        },
    });


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            sx={{borderRadius: '30px'}}
        >
            <DialogTitle sx={{backgroundColor: '#FAFAFA', padding: '10px 80px'}}>
                <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '26px',
                        color: '#4A4A4A',
                        textAlign: 'center',
                        m: "44px auto 28px"
                    }}
                >{data?.title}</Typography>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <X size={24} color="#525252"/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{backgroundColor: '#FAFAFA', p: "0 100px"}}>

                <FlexBox gap="55" align="center">
                    <StyledImg src={data?.imageUrl} width={150} height={350}/>
                    <FlexBox direction="column" gap='16'>
                        <FlexBox align="center" gap="8">
                            <StyledBoldText fontSize={18} fontWeight={400}>{data?.country}</StyledBoldText>
                            <StyledImg src={data?.countryUrl} width={35} height={25}/>
                        </FlexBox>
                        <FlexBox align="center" gap="8">
                            <StyledBoldText fontSize={20} fontWeight={700}>Color:</StyledBoldText>
                            <StyledBoldText fontSize={20} fontWeight={400}>{data?.color}</StyledBoldText>
                        </FlexBox>
                        <FlexBox align="center" gap="8">
                            <StyledBoldText fontSize={20} fontWeight={700}>Wine type:</StyledBoldText>
                            <StyledBoldText fontSize={20} fontWeight={400}>{data?.type}</StyledBoldText>
                        </FlexBox>
                        <FlexBox align="center" gap="8">
                            <StyledBoldText fontSize={20} fontWeight={700}>Last price:</StyledBoldText>
                            <StyledBoldText fontSize={20} fontWeight={400}>{data?.price}</StyledBoldText>
                        </FlexBox>
                        {/*<FlexBox align="center" gap="8">*/}
                        {/*    <StyledBoldText fontSize={20} fontWeight={700}>Last month price:</StyledBoldText>*/}
                        {/*    <StyledBoldText fontSize={20} fontWeight={400}>{data?.priceChanges[0]}</StyledBoldText>*/}
                        {/*</FlexBox>*/}
                        {/*<FlexBox align="center" gap="8">*/}
                        {/*    <StyledBoldText fontSize={20} fontWeight={700}>Last 3 months price:</StyledBoldText>*/}
                        {/*    <StyledBoldText fontSize={20} fontWeight={400}>{data?.priceChanges[1]}</StyledBoldText>*/}
                        {/*</FlexBox>*/}
                        {/*<FlexBox align="center" gap="8">*/}
                        {/*    <StyledBoldText fontSize={20} fontWeight={700}>Last 12 months price:</StyledBoldText>*/}
                        {/*    <StyledBoldText fontSize={20} fontWeight={400}>{data?.priceChanges[2]}</StyledBoldText>*/}
                        {/*</FlexBox>*/}

                        <StyledButton sx={{mt: 1}} variant="contained" onClick={() => formik.handleSubmit()}>Buy bottle
                            for {data?.price}</StyledButton>

                    </FlexBox>
                </FlexBox>

                <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 300,
                        fontSize: '16px',
                        color: 'rgba(82, 82, 82, 1)',
                        textAlign: 'center',
                        m: "40px 0px 20px"
                    }}
                >To execute a transaction, it must be confirmed by the manager</Typography>

            </DialogContent>
        </Dialog>
    )
}

export default BuyInvestmentsDialog

const StyledButton = styled(Button)`
  padding: 28px 48px;
  font-size: 22px;
  font-weight: 700;
  text-transform: none;
`

const FlexBox = styled.div<FlexProps>`
  display: flex;
  gap: ${(props) => props.gap ? `${props.gap}px` : '8px'};
  flex-direction: ${(props) => props.direction ? `${props.direction}` : 'row'};
  align-items: ${(props) => props.align ? `${props.align}` : 'start'};
`
const StyledImg = styled.img`
  object-fit: cover;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

const StyledBoldText = styled.span<FontColorProps>`
  font-size: ${(props) => props.fontSize ? `${props.fontSize}px` : '18px'};
  font-weight: ${(props) => props.fontWeight || 700};
  color: #000;
`;