import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {CustomerFeedbackContentText} from "../../../utils/constants";

const CustomerFeedback = () => {
    const navigate = useNavigate()
    const { formatMessage } = useIntl()


    return (
        <IntroWrapper id="clientsReviews">
            <FlexBox>
                <Typography
                    variant="h1"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '48px',
                        color: '#FFFFFF',
                        mb: '80px'
                    }}
                >
                    {formatMessage({id: "customerReviewsTitle"})}
                </Typography>
                <ContainerFlexBox>
                    {CustomerFeedbackContentText.map((b, index) => {
                        return (
                            <Container key={index}>
                                <StyledBox>

                                <StyledImg src={b.img}/>
                                <img style={{alignSelf: 'center'}} alt="img" src={b.rating} height={24}/>
                                <Typography
                                    variant="h1"
                                    component="div"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 300,
                                        fontSize: '24px',
                                        color: '#FFFFFF',
                                        width: '340px',
                                        textAlign: 'center',
                                        mb:2
                                    }}
                                >
                                    {formatMessage({id: b.title})}

                                </Typography>
                                </StyledBox>

                                <Typography
                                    variant="h1"
                                    component="div"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 300,
                                        fontStyle:'italic',
                                        fontSize: '20px',
                                        color: '#DDD9C9',
                                        width: '340px',
                                        textAlign: 'center'
                                    }}
                                >
                                    {formatMessage({id: b.customerName})}
                                </Typography>
                            </Container>

                        )
                    })}
                </ContainerFlexBox>

                <Button variant={"outlined"} color={"info"} size={"large"} onClick={() => navigate('/sign-up')}>{formatMessage({id: "registration"})}</Button>


            </FlexBox>
        </IntroWrapper>
    )
}

export default CustomerFeedback

const IntroWrapper = styled.div`
  width: 100%;
  min-width: 100vh;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
  background: #23242D;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ContainerFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 40px;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 25px;
  background-color: #6C685A;
  height: 385px;
  justify-content: space-between;
`
const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #6C685A;
  height: 385px;
  gap:16px
`
const StyledImg = styled.img`
width: 110px;
  height: 110px;
  border-radius: 50%;
  align-self: center;
`