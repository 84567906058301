import { createApi } from '@reduxjs/toolkit/dist/query/react'
import baseQueryWithIam from '../../app/baseQueryWithIam'
import {FaqsState, ManagerState} from "../types";
import {MessageState} from "../../pages/user/Support/components/SupportDialog";


export const faqsApiSlice = createApi({
    reducerPath: 'faqsApi',
    baseQuery: baseQueryWithIam,
    tagTypes: ['Faqs'],
    endpoints: (build) => ({
        fetchFaqs: build.query<FaqsState, void>({
            query: () => ({
                url: `/v1/faqs`,
            }),
            transformResponse: (response: FaqsState) => response
        }),
        fetchMyManager: build.query<ManagerState, void>({
            query: () => ({
                url: `/v1/myManager`,
            }),
            transformResponse: (response: ManagerState) => response
        }),
        leaveMessage: build.mutation<number, MessageState>({
            query: (message) => ({
                url: '/v1/leaveMessage',
                method: 'POST',
                body: message,
            }),
            invalidatesTags: [{ type: 'Faqs', id: 'LIST' }],
        }),
        requestACall: build.query<any, any>({
            query: (params) => ({
                url: `/v1/requestCall`,
            }),
            transformResponse: (response: any) => response
        }),
    }),
})
