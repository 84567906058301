import React, {useState} from 'react'
import * as yup from 'yup'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, IconButton, InputAdornment, Typography,
} from '@mui/material'
import styled from "@emotion/styled";
import {X} from "@phosphor-icons/react";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {requiredString} from "../../../../utils/validation";
import {useIntl} from "react-intl";
import CustomInput from "../../../../components/muiComponents/customInput";
import {accountApiSlice} from "../../../../features/account/accountSlice";
import {ChangePasswordState} from "../../../../features/types";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {resetError} from "../../../../features/user/userSlice";
import {useAppDispatch} from "../../../../app/hooks";

export interface MessageState {
    message: string
}

const ChangePasswordDialog = ({open, handleClose}) => {
    const {formatMessage} = useIntl()
    const dispatch = useAppDispatch()
    const [loginInfo, setLoginInfo] = useState({email: '', password: ''})
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [changePassword] = accountApiSlice.useChangePasswordMutation()

    const message: ChangePasswordState = {
        oldPassword: '',
        newPassword: ''
    }

    const validationSchema = yup.object().shape({
        oldPassword: requiredString({formatMessage}),
        newPassword: requiredString({formatMessage}),
    })

    const handleChangePassword = async () => {
        try {
            await changePassword({
                ...formik.values,
            }).unwrap()
            toast.success(formatMessage({id: 'changedSuccess'}))
            handleClose()
        } catch (error) {
            toast.error((formatMessage({id: 'errorInChangingPassword'})))
        }
    };

    const formik = useFormik({
        initialValues: message,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async () => {
            handleChangePassword()
        },
    })

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault()
    }

    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
        >
            <DialogTitle sx={{ backgroundColor: '#FAFAFA' }}>
                <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '28px',
                        color: '#4A4A4A',
                        textAlign: 'center',
                        m: "44px auto 28px"
                    }}
                >{formatMessage({id: "changeYourPassword"})}</Typography>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <X size={24} color="#525252"/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ backgroundColor: '#FAFAFA', p: "0 60px" }}>

                <CustomInput
                    id="oldPassword"
                    name="oldPassword"
                    type={showOldPassword ? 'text' : 'password'}
                    value={formik.values.oldPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.oldPassword}
                    error={Boolean(formik.errors.oldPassword)}
                    label={formatMessage({id: "oldPassword"})}
                    color="secondary"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowOldPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showOldPassword ? (
                                        <VisibilityOff/>
                                    ) : (
                                        <Visibility/>
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        mb: '36px',
                        '& .MuiInputBase-input': {
                            alignItems: 'start',
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            border: '1px solid rgba(199, 199, 199, 1)',
                            borderRadius: '8px'
                        },
                        '& .MuiOutlinedInput-input': {
                            alignItems: 'start',
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            border: '1px solid rgba(199, 199, 199, 1)',
                            borderRadius: '8px'
                        },
                    }}
                    size="medium"
                />

                <CustomInput
                    id="newPassword"
                    name="newPassword"
                    type={showNewPassword ? 'text' : 'password'}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.newPassword}
                    error={Boolean(formik.errors.newPassword)}
                    label={formatMessage({id: "newPassword"})}
                    color="secondary"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showNewPassword ? (
                                        <VisibilityOff/>
                                    ) : (
                                        <Visibility/>
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        mb: '36px',
                        '& .MuiInputBase-input': {
                            alignItems: 'start',
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            border: '1px solid rgba(199, 199, 199, 1)',
                            borderRadius: '8px'
                        },
                        '& .MuiOutlinedInput-input': {
                            alignItems: 'start',
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            border: '1px solid rgba(199, 199, 199, 1)',
                            borderRadius: '8px'
                        },
                    }}
                    size="medium"
                />

                <StyledButton color="secondary" variant="contained" fullWidth onClick={() => formik.handleSubmit()} size="large">{formatMessage({id: "submit"})}</StyledButton>

            </DialogContent>
        </Dialog>
    )
}

export default ChangePasswordDialog

const StyledButton = styled(Button)`
  background-color: #292D34;
  color: #FFF;
  margin-bottom: 40px;
`