import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";

const Intro = () => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    return (
        <IntroWrapper>
            <FlexBox>
                <Typography
                    variant="h1"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '72px',
                        color: '#FFFFFF',
                        mb: '23px'
                    }}
                >
                    {formatMessage({id: "takePartInAWineAdventure"})}
                </Typography>

                <Typography
                    variant="h1"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 300,
                        fontSize: '42px',
                        color: '#E3E3BE',
                        mb: '67px'
                    }}
                >
                    {formatMessage({id: "becomeShareholder"})}
                </Typography>

                <Button variant={"contained"} color={"primary"} size={"large"} onClick={() => navigate('/sign-up')}>{formatMessage({id: "registration"})}</Button>


            </FlexBox>
        </IntroWrapper>
    )
}

export default Intro

const IntroWrapper = styled.div`
  width: 100%;
  min-width: 100vh;
  height: 100%;
  min-height: 100vh;
  background-image: url(${require("../../../assets/img/Landing/Intro/bg-advantages.png")});
  background-size: cover; 
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`