import React, {useState} from 'react'
import styled from "@emotion/styled";
import {Body1, H1, H4} from "../../../../layouts/TextStyles";
import {Box} from "@mui/system";
import {useIntl} from "react-intl";
import { MyPortfolioState} from "../../../../features/types";
import {myPortfolioApiSlice} from "../../../../features/myPortfolio/myPortfolio";
import {Button, LinearProgress} from "@mui/material";

interface FontColorProps {
    color: string;
    fontSize?: number;
    fontWeight?: number;

}

const PortfolioMainContent = () => {
    const {formatMessage} = useIntl()
    const {data: data = {} as MyPortfolioState, isFetching} = myPortfolioApiSlice.useFetchPortfolioQuery()
    const content = [
        {
            title: "exploreYourWinePortfolio",
        }
    ]
    const InfoContent = [
        {
            title: "last7DaysReturn",
            amount: "+158,29$",
            percentage: "(+1,30%)"
        },
        {
            title: "last30DaysReturn",
            amount: "+2812,01$",
            percentage: "(+5,11%)"
        },
        {
            title: "last12MonthReturn",
            amount: "+6104,21$",
            percentage: "(+24,76%)"
        },

    ]


    return (
        <>
            {isFetching ? (
                <LinearProgress/>
            ) : (
                <Box sx={{mb: '48px'}}>
                    <H4 style={{
                        marginBottom: '16px',
                        textDecoration: 'underline'
                    }}> {formatMessage({id: content[0].title})}</H4>

                    <FlexBox style={{gap: '20px'}}>
                        <TotalValuationWrapper>
                            <Body1 color="#D2D2D2">{formatMessage({id: "totalValuation"})}</Body1>
                            <H1 style={{marginBottom: "16px"}} color="#FFF">{data?.totalBalance}</H1>
                            {InfoContent.map((b, index) => {
                                return (
                                    <Body1 key={index} style={{fontSize: '18px', marginBottom: '8px'}} color="#D2D2D2">
                                        {formatMessage({id: b.title})}
                                        <StyledBoldText color="#FFF">{" "}{b.amount}</StyledBoldText>
                                        <StyledBoldText color="#D2D2D2">{" "}{b.percentage}</StyledBoldText>
                                    </Body1>
                                )
                            })}

                        </TotalValuationWrapper>
                        <Box>
                            <GridBox>
                                <StatisticsWrapper style={{justifyContent: "space-around"}}>
                                    <StyledBoldText color="#525252" fontSize={16} fontWeight={500}
                                                    style={{textDecoration: "underline"}}>
                                        {formatMessage({id: "totalInTheFunds"})}:
                                    </StyledBoldText>
                                    <StyledBoldText color="#525252" fontSize={48} fontWeight={400}>
                                        {data?.funds?.total}
                                    </StyledBoldText>
                                </StatisticsWrapper>

                                <StatisticsWrapper style={{justifyContent: "space-around"}}>
                                    <StyledBoldText color="#525252" fontSize={16} fontWeight={500}
                                                    style={{textDecoration: "underline"}}>
                                        {formatMessage({id: "unInvestedCash"})}:
                                    </StyledBoldText>
                                    <StyledBoldText color="#525252" fontSize={48} fontWeight={400}>
                                        {data?.uninvestedCash}
                                    </StyledBoldText>
                                </StatisticsWrapper>

                                <StatisticsWrapper style={{justifyContent: "space-around"}}>
                                    <StyledBoldText color="#525252" fontSize={16} fontWeight={500}>
                                        {formatMessage({id: "totalAmountOfBottles"})}:
                                    </StyledBoldText>
                                    <StyledBoldText color="#525252" fontSize={48} fontWeight={400}>
                                        {data?.bottles?.total}
                                    </StyledBoldText>
                                    <StyledBoldText color="#525252" fontSize={20} fontWeight={400}
                                                    style={{textDecoration: "underline"}}>
                                        {data?.bottles?.amount} {formatMessage({id: "bottles"})}
                                    </StyledBoldText>
                                </StatisticsWrapper>

                                {data?.funds?.funds?.map((b, index) => {
                                    return (
                                        <StatisticsWrapper key={index}>
                                            <StyledBoldText color="#525252" fontSize={16} fontWeight={500}>
                                                {b.title}:
                                            </StyledBoldText>
                                            <StyledBoldText color="#525252" fontSize={48} fontWeight={500}>
                                                {b.total}
                                            </StyledBoldText>
                                            <StyledBoldText color="#525252" fontSize={20} fontWeight={400}
                                                            style={{textDecoration: "underline"}}>
                                                {b.amount} {formatMessage({id: "stocks"})}
                                            </StyledBoldText>
                                        </StatisticsWrapper>
                                    )
                                })}

                            </GridBox>
                        </Box>
                    </FlexBox>
                </Box>
            )}
        </>

    )
}

export default PortfolioMainContent


const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`
const TotalValuationWrapper = styled.div`
  background-color: #1F1F1F;
  padding: 40px 36px;
  width: 50%;
  border-radius: 4px;
`
const StatisticsWrapper = styled.div`
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 25px;
  border-radius: 4px;
  border: 1px solid #CECECE;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`
const StyledBoldText = styled.span<FontColorProps>`
  font-size: ${(props) => props.fontSize ? `${props.fontSize}px` : '18px'};
  font-weight: ${(props) => props.fontWeight || 700};
  color: ${(props) => props.color};
`;

const ShowMoreButton = styled(Button)`
  border-radius: 6px;
`