import {createApi} from "@reduxjs/toolkit/dist/query/react";
import baseQueryWithIam from "../../app/baseQueryWithIam";
import {RegisteredData, RegisterState} from "../types";


export interface VerifyState {
    userId: string
    code:string
}

export const userRegisterSlice = createApi({
    reducerPath: 'userRegisterApi',
    baseQuery: baseQueryWithIam,
    tagTypes: ['Register'],
    endpoints: (build) => ({
        registerUser: build.mutation<RegisteredData, RegisterState>({
            query: (register) => ({
                url: '/v1/register',
                method: 'POST',
                body: register,
            }),
            invalidatesTags: [{ type: 'Register', id: 'LIST' }],
            transformResponse: (response: RegisteredData) => response,
        }),
        verifyUser: build.mutation<any, VerifyState>({
            query: (register) => ({
                url: '/v1/verify',
                method: 'POST',
                body: register,
            }),
            invalidatesTags: [{ type: 'Register', id: 'LIST' }],
        }),
    }),
})