import React, {useState} from 'react'
import styled from "@emotion/styled";
import {Button, Typography} from "@mui/material";
import {H4} from "../../../../layouts/TextStyles";
import {Box} from "@mui/system";
import {useIntl} from "react-intl";
import InvestAndSellDialog from "./InvestAndSellDialog";
import {FundsState} from "../../../../features/types";
import {fundsApiSlice} from "../../../../features/funds/fundsSlice";
import Sommelier from "../../../../assets/img/somielers-fund.png"
import Bordeaux from "../../../../assets/img/bordeaux-fund.png"

interface ContentWrapperProps {
    bgImg: string;
}

const MainContent = () => {

    const {formatMessage} = useIntl()
    const content = [
        {
            title: "Explore diversified wine investment funds",
            desc: "From rare vintages to emerging vineyards, these funds offer a unique blend of luxury and investment\n" +
                "                potential. Immerse yourself in the world of wine investment, where each bottle tells a story and every\n" +
                "                vintage holds the promise of both taste and financial appreciation. Explore our diversified wine\n" +
                "                investment funds and uncork a new dimension of investment sophistication!"
        }
    ]

    const [selectedDialogTitle, setSelectedDialogTitle] = useState('');
    const [selectedDialogContent, setSelectedDialogContent] = useState('');
    const [openDialog, setOpenDialog] = useState(false)

    const handleOpenDialog = (title,content) => {
        setSelectedDialogTitle(title);
        setSelectedDialogContent(content)
        setOpenDialog(true);
    };

    const {
        data: data = {} as FundsState
    } = fundsApiSlice.useFetchFundsQuery()

    return (
        <Box sx={{mb: '48px'}}>
            <H4 style={{marginBottom: '16px', textDecoration: 'underline'}}> {content[0].title}</H4>
            <Typography
                variant="h5"
                component="div"
                color="text.secondary"
                sx={{
                    fontWeight: 400,
                    fontSize: '20px',
                    color: '##525252',
                    mb: '24px'
                }}
            >
                {content[0].desc}
            </Typography>


            <FlexBox style={{gap: '20px'}}>
                <GridBox>
                    {data?.funds?.length ? (
                        data?.funds?.map((b, index) => {
                                return (
                                    <ContentWrapper key={index} bgImg={data?.funds?.length === 1 ? Sommelier : Bordeaux }>
                                        <Typography
                                            variant="h1"
                                            component="div"
                                            color="text.secondary"
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '24px',
                                                color: '#FFF',
                                                mb: '8px'
                                            }}>
                                            {formatMessage({id: b.title})}
                                        </Typography>
                                        <Typography
                                            variant="h1"
                                            component="div"
                                            color="text.secondary"
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '20px',
                                                color: '#EEEEEE',
                                                mb: '16px',
                                                lineHeight: '1.4'
                                            }}>
                                            {formatMessage({id: b.description})}
                                        </Typography>
                                        {/*{b.desc2.map((data, index) => {*/}
                                        {/*    return (*/}
                                        {/*        <Typography*/}
                                        {/*            key={index}*/}
                                        {/*            variant="h1"*/}
                                        {/*            component="div"*/}
                                        {/*            color="text.secondary"*/}
                                        {/*            sx={{*/}
                                        {/*                fontWeight: 400,*/}
                                        {/*                fontSize: '16px',*/}
                                        {/*                color: '#FFF',*/}
                                        {/*                mb: '12px'*/}
                                        {/*            }}>*/}
                                        {/*            {formatMessage({id: data.title})}*/}
                                        {/*        </Typography>*/}
                                        {/*    )*/}
                                        {/*})}*/}
                                        <Typography
                                            key={index}
                                            variant="h1"
                                            component="div"
                                            color="text.secondary"
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '20px',
                                                color: '#EDEDED',
                                                mb: '24px',
                                                textDecoration: "underline"
                                            }}>
                                            Share price: {b.sharePrice}
                                        </Typography>
                                        <FlexBox>
                                            <StyledButton sx={{mr:'20px'}}
                                                          onClick={() => handleOpenDialog('Invest here', 'You can invest in the funds through your personal manager')}
                                            >Invest here</StyledButton>
                                            <StyledButton
                                                onClick={() => handleOpenDialog('Sell here', 'You can sell your shares in the funds through your personal manager')}
                                            >Sell shares</StyledButton>
                                        </FlexBox>
                                        <InvestAndSellDialog
                                            open={openDialog}
                                            handleClose={() => setOpenDialog(false)}
                                            title={selectedDialogTitle}
                                            content={selectedDialogContent}
                                        />
                                    </ContentWrapper>
                                )
                            })):("")

                    }

                </GridBox>
                <OtherFundWrapper>
                    <Typography
                        variant="h1"
                        component="div"
                        color="text.secondary"
                        sx={{
                            fontWeight: 300,
                            fontSize: '20px',
                            color: '#FFF',
                            mb: '24px',
                            lineHeight: 1.7
                        }}>
                        The return on funds as a whole is higher than the average return on one bottle per year by:
                    </Typography>
                    <Typography
                        variant="h1"
                        component="div"
                        color="text.secondary"
                        sx={{
                            fontWeight: 700,
                            fontSize: '92px',
                            color: '#FFF',
                            mb: '24px',
                        }}>
                        6%
                    </Typography>
                </OtherFundWrapper>
            </FlexBox>
        </Box>

    )
}
export default MainContent
const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
`
const ContentWrapper = styled.div<ContentWrapperProps>`
  background-image: url(${props => props.bgImg});
  padding: 28px 20px;
  background-size: cover;
  background-repeat: no-repeat;
`
const OtherFundWrapper = styled.div`
  background: url(${require("../../../../assets/img/other-fund.png")});
  padding: 28px 20px;
  background-size: cover;
  background-repeat: no-repeat;
  
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`

const StyledButton = styled(Button)`
  background-color: #FFF;
  color: #000;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`
