import React from 'react'
import Navbar from "../../../components/navbar/NavBar";
import styled from "@emotion/styled";
import LandingFooter from '../../Home/LandingFooter/LandingFooter';
import MainContent from "./components/MainContent";
import WineCollection from "./components/WineCollection";
import Footer from "../Footer/Footer";

const Trading = () => {

    return (
        <Wrapper>
            <Navbar/>
            <Main>
               <MainContent/>
                <WineCollection/>
            </Main>
            <Footer/>
        </Wrapper>

    )
}

export default Trading

const Wrapper = styled.div`
  background-color: #F1F3F6;
  min-height: 100vh;
  height: 100%;
`

const Main = styled.main`
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px 40px;
`
