import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import baseQueryWithIam from '../../app/baseQueryWithIam'
import customHistory from '../../utils/history'
import storage from '../../utils/storageService'
import { userLogin } from './userActions'

interface UserState {
    loading: boolean
    userInfo: {
        lang: string
        access: string
        refresh: string
    }
    error: boolean
    success: boolean
}

const initialState: UserState = {
    loading: false,
    userInfo: storage?.get('user') || {
        lang: 'en',
        access: '',
        refresh: '',
    },
    error: false,
    success: false, // for monitoring the registration process.
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            state.userInfo = { lang: state.userInfo.lang, ...action.payload }
            storage?.set('access', action.payload.data.access)
            storage?.set('refresh', action.payload.data.refresh)
            storage?.set('user', {
                ...action.payload,
                lang: storage?.get('language') || 'en',
            })
        },

        setLanguage: (state, action: PayloadAction<string>) => {
            storage?.set('language', action.payload)
            state.userInfo.lang = action.payload
        },

        resetError: (state) => {
            state.error = false
        },

        logout: (state) => {
            storage?.remove('access')
            storage?.remove('refresh')
            storage?.remove('user')

            state.success = false
            state.userInfo = {
                ...initialState.userInfo,
            }
            customHistory.push('/')
        },
    },
    extraReducers: {
        [userLogin.pending.toString()]: (state) => {
            state.loading = true
            state.error = false
            state.success = false
        },

        [userLogin.fulfilled.toString()]: (state, { payload }) => {
            state.loading = false
            state.userInfo = { lang: state.userInfo.lang, ...payload }
            state.success = true
            storage?.set('user', {
                ...payload,
                lang: storage?.get('language') || 'en',
            })
        },

        [userLogin.rejected.toString()]: (state) => {
            state.loading = false
            state.error = true
            state.success = false
        },
    },
})

export const userApiSlice = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithIam,
    endpoints: (build) => ({
        deleteUser: build.mutation<void, number>({
            query: (id) => ({
                url: `/user/${id}`,
                method: 'DELETE',
            }),
        }),

        resetTemporaryPassword: build.mutation<void, number>({
            query: (id) => ({
                url: `/user/${id}/reset-password`,
                method: 'POST',
            }),
        }),

        changePassword: build.mutation<void, string>({
            query: (password) => ({
                url: `/auth/force-change-password`,
                method: 'POST',
                body: { password },
            }),
        }),
    }),
})

export const lang = (state: RootState) => state.user.userInfo.lang

function langToLocalizedLang(lang: string) {
    if (lang === 'en') {
        return 'EN'
    } else if (lang === 'gr') {
        return 'DE'
    } else {
        return undefined
    }
}

export function getValueFromLocalizedString(localizedString, language: string): string {
    const lang = langToLocalizedLang(language)
    const localizedValue = localizedString.valuesByLanguages[lang]
    const defaultValue = localizedString.valuesByLanguages['EN']
    if (!localizedValue) {
        return defaultValue
    } else {
        return localizedValue
    }
}


export const userInfo = (state: RootState) => state.user.userInfo

export const { resetError, setLanguage, setCredentials, logout } =
    userSlice.actions

export default userSlice.reducer
