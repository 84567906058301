import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import styled from "@emotion/styled";
import {Box, Button, LinearProgress} from '@mui/material';
import {Body1, H2, H4, Caption, BoldText} from "../../../../layouts/TextStyles";
import grapeForWine from "../../../../assets/img/grape-for-wine.png"
import {WineAccountDescriptionText} from "../../../../utils/constants";
import AccountTable from './AccountTable';
import {accountApiSlice} from "../../../../features/account/accountSlice";
import {AccountState, NewsState,} from "../../../../features/types";
import {newsApiSlice} from "../../../../features/news/newsSlice";
import ChangePasswordDialog from "./ChangePasswordDialog";
import WithdrawalAndAddFundsDialog from "./WithdrawalAndAddFundsDialog";
import { getValueFromLocalizedString, lang } from '../../../../features/user/userSlice'
import { useAppSelector } from '../../../../app/hooks'


const AccountInfo = () => {
    const {formatMessage} = useIntl()

    const language = useAppSelector(lang)

    const {
        data: data = {} as AccountState, isFetching
    } = accountApiSlice.useFetchAccountQuery()

    const {
        data: newsData = {} as NewsState
    } = newsApiSlice.useFetchNewsQuery()

    const PersonalInfoContent = [
        {
            title: "fullName",
            answer: `${data.fullName}`
        },
        {
            title: "mobileNumber",
            answer: `${data.phone}`
        },
        {
            title: "country",
            answer: `${data.country}`
        },
        {
            title: "email",
            answer: `${data.email}`
        },

    ]

    const [openDialog, setOpenDialog] = useState(false)
    const [openDialogSecond, setOpenDialogSecond] = useState(false)
    const [selectedDialogTitle, setSelectedDialogTitle] = useState('');

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleOpenDialogSecond = (title) => {
        setSelectedDialogTitle(title);
        setOpenDialogSecond(true);
    };

    return (
        <>
            {isFetching ? (
                <LinearProgress/>
            ) : (
                <Box>
                    <GridBox style={{marginBottom: '40px'}}>
                        <Box style={{display: "flex", flexDirection: 'column', justifyContent: 'space-between'}}>
                            <BalanceWrapper style={{backgroundColor: '#1F1F1F'}}>
                                <Body1 color="#fff" style={{marginBottom: '14px'}}>
                                    {formatMessage({id: "estAccountBalance"})}
                                </Body1>
                                <H2 color="#fff" style={{marginBottom: '38px'}}>{data.balance}</H2>
                                <FlexBox>
                                    <StyledWhiteButton
                                        onClick={() => handleOpenDialogSecond('Add Funds')}
                                        variant="contained"
                                        size="medium"
                                    >
                                        {formatMessage({id: "addFunds"})}
                                    </StyledWhiteButton>
                                    <StyledStrokeWhiteButton
                                        onClick={() => handleOpenDialogSecond('Withdrawal')}
                                        size="medium"
                                    >
                                        {formatMessage({id: "withdrawal"})}
                                    </StyledStrokeWhiteButton>
                                </FlexBox>
                            </BalanceWrapper>
                            <BalanceWrapper style={{padding: 0}}>
                                <Title>{formatMessage({id: "personalSettings"})}</Title>
                                {PersonalInfoContent.map((b, index) => {
                                    return (
                                        <FlexBox key={index}>
                                            <Body1>{formatMessage({id: b.title})}:</Body1>
                                            <Caption>{b.answer}</Caption>
                                        </FlexBox>
                                    )
                                })}


                                <StyledButton sx={{mt: '12px'}} variant="outlined" size="small"
                                              color="secondary"
                                              onClick={() => handleOpenDialog()}>{formatMessage({id: "changePassword"})}</StyledButton>
                            </BalanceWrapper>

                            <BalanceWrapper style={{backgroundColor: '#FFF', border: "1px solid #D9D9D9"}}>
                                <Title>{formatMessage({id: "yourManager"})} </Title>
                                {data?.manager === null ?
                                   (
                                       <Body1>Your manager will be here soon..</Body1>
                                   ) :(
                                        <FlexBox>
                                            <StyledImg src={data?.manager?.imageUrl}/>
                                            <Box>
                                                <FlexBox style={{marginBottom: '0px'}}>
                                                    <Body1>{data?.manager?.fullName} </Body1>
                                                    <Caption>({data?.manager?.phone})</Caption>
                                                </FlexBox>
                                                <Body1>{data?.manager?.description}</Body1>
                                            </Box>

                                        </FlexBox>
                                    )}


                            </BalanceWrapper>
                            <ChangePasswordDialog
                                open={openDialog}
                                handleClose={() => setOpenDialog(false)}
                            />

                            <WithdrawalAndAddFundsDialog
                                open={openDialogSecond}
                                handleClose={() => setOpenDialogSecond(false)}
                                title={selectedDialogTitle}
                            />

                        </Box>

                        <WineMarkerWrapper>
                            <Title>{formatMessage({id: "wineMarketNews"})}</Title>
                            {newsData.items?.map((b, index) => {
                                return (
                                    <Box key={b._id}>
                                        <Body1 color="#000" style={{marginBottom: '24px', fontWeight: 700}}>
                                            {getValueFromLocalizedString(b.localizedTitle, language)}
                                        </Body1>
                                        <img src={b.imageUrl} alt='wine image' width={'100%'} height={267}
                                             style={{objectFit: 'cover', marginBottom: '24px'}}/>
                                        <Body1 color="#000" style={{marginBottom: '24px'}}>
                                            {getValueFromLocalizedString(b.localizedDescription, language)}
                                        </Body1>
                                    </Box>
                                )
                            })}

                        </WineMarkerWrapper>

                    </GridBox>
                    <H4 style={{
                        marginBottom: '16px',
                        textDecoration: 'underline'
                    }}>{formatMessage({id: "lastDealsAndTradeHistory"})}</H4>
                    <AccountTable/>
                </Box>
            )}
        </>

    )
}

export default AccountInfo

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-around;
  width: 100%;
  gap: 40px;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`
const BalanceWrapper = styled.div`
  padding: 26px 32px;
  border-radius: 5px;
`

const WineMarkerWrapper = styled.div`
  background-color: #FFFFFF;
  padding: 22px 26px;
  border-radius: 5px;
  height: 650px;
  overflow-y: scroll;
`
const StyledButton = styled(Button)`
  border-radius: 7px;
  background-color: #EEEEEE;
  border: 1px solid #A5A5A5;
  color: #444444;
`
const StyledWhiteButton = styled(Button)`
  border-radius: 100px;
  background-color: #FFFFFF;
  color: #444444;

  &:hover {
    background-color: rgba(255, 255, 255, 0.90);
  }
`
const StyledStrokeWhiteButton = styled(Button)`
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.14);
  border: 1px solid #FFFFFF;
  color: #FFF;

  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
  }
`
const StyledImg = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 100px;
`

const Title = styled(H4)`
  margin-bottom: 16px;
  text-decoration: underline;
`