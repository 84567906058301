import { createApi } from '@reduxjs/toolkit/dist/query/react'
import baseQueryWithIam from '../../app/baseQueryWithIam'
import {ChangePasswordState, InvestmentBuyOrSaleState, MyBottlesState} from "../types";

export const myBottlesApiSlice = createApi({
    reducerPath: 'myBottlesApi',
    baseQuery: baseQueryWithIam,
    tagTypes: ['MyBottles'],
    endpoints: (build) => ({
        fetchWineBottles: build.query<MyBottlesState, void>({
            query: () => ({
                url: `/v1/myBottles`,
            }),
            transformResponse: (response: MyBottlesState) => response
        }),
        sellInvestments: build.mutation<any, InvestmentBuyOrSaleState>({
            query: (sell) => ({
                url: '/v1/sellInvestment',
                method: 'POST',
                body: sell,
            }),
            transformResponse: (response: any) => response,
        }),
        buyInvestments: build.mutation<any, InvestmentBuyOrSaleState>({
            query: (buy) => ({
                url: '/v1/buyInvestment',
                method: 'POST',
                body: buy,
            }),
            transformResponse: (response: any) => response,
        }),
    }),
})
