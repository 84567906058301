import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    useTheme,
} from '@mui/material'
import {Body1, H3} from '../../layouts/TextStyles'
import CustomInput from '../muiComponents/customInput/CustomInput'
import logo from '../../assets//img/Logo.png'
import Arrow from "../../assets/img/back-arrow.png";
import {useNavigate} from "react-router-dom";
import { RegisterState} from "../../features/types";
import * as yup from "yup";
import {requiredString} from "../../utils/validation";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import {userRegisterSlice} from "../../features/user/userRegisterSlice";




const SignupForm = () => {
    const theme = useTheme()
    const styles = {
        logo: {
            width: 150,
            height: 22,
            margin: '0 auto 32px',
        },
        radioGroup: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 16,
        },
        smsCodeBox: {
            mt: 4,
            width: '760px',
            display: 'grid',
            justifyContent: 'center',
            background: 'white',
            borderRadius: 1,
            padding: 4,
            textAlign: 'center',
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        flexRowCenter: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        fullWidth: {
            width: '100%',
        },
        toggleSpan: {
            color: theme.palette.primary.main,
            marginLeft: 4,
            cursor: 'pointer',
        },

    }
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const [showPassword, setShowPassword] = useState(false)

    const [register] = userRegisterSlice.useRegisterUserMutation()

    const user: RegisterState = {
        fullName: '',
        phone: '',
        country: '',
        email: '',
        password: ''
    }

    const validationSchema = yup.object().shape({
        fullName: requiredString({formatMessage}),
        phone: requiredString({formatMessage}),
        country: requiredString({formatMessage}),
        email: requiredString({formatMessage}),
        password: requiredString({formatMessage}),
    })

    const handleRegisterUser = async () => {
        try {
            const registeredUser =await register({
                ...formik.values,
            }).unwrap()
            handleRowClick(registeredUser.userId)
            toast.success(formatMessage({id: 'registeredSuccess'}))
        } catch (error) {
            toast.error((formatMessage({id: 'errorInRegisteredUser'})))
        }
    };


    const handleRowClick = (userId) => {
        navigate(`/verify/${userId}`);
    }

    const formik = useFormik({
        initialValues: user,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async () => {
            handleRegisterUser()
        },
    })


    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault()
    }


    return (
        <Box mt="10px">
            <Wrapper >
                <FlexBox>
                    <Box sx={{display: "flex", gap: '8px', alignItems: "center", cursor: 'pointer'}}  onClick={() => navigate('/')}>
                        <img alt="arrow" src={Arrow} width={18} height={16}/>
                        <Body1 color={theme.palette.secondary.main}>{formatMessage({id: "goBack"})}</Body1>
                    </Box>
                    <Body1 style={{cursor: 'pointer'}} color={theme.palette.secondary.main} onClick={() => navigate('/login')}>{formatMessage({id: "login"})}</Body1>

                </FlexBox>
                <img
                    alt="logo"
                    src={logo}
                    style={{width: 170, height: 98, margin: '0 auto 12px'}}
                />

                <H3 color="#525252" style={{margin: '0 auto '}}>{formatMessage({id: "registration"})}</H3>


                <Box sx={styles.flexRow}>
                    <Box
                        sx={styles.fullWidth}
                        mr={4}
                    >
                        <CustomInput
                            name="fullName"
                            value={formik.values.fullName}
                            onChange={(e) => {
                                const re = /^[a-zA-Z ]*$/;

                                if (e.target.value === '' || re.test(e.target.value)) {
                                    formik.handleChange(e); // Update Formik state with the new value
                                }
                            }}
                            onBlur={formik.handleBlur}
                            helperText={formik.errors.fullName}
                            error={Boolean(formik.errors.fullName)}
                            label={formatMessage({id: "fullName"})}
                            color="secondary"
                            fullWidth
                            size="medium"
                            inputProps={{
                                pattern: '^[a-zA-Z]+$',
                                title: 'Please enter letters only'
                            }}
                        />
                    </Box>

                    <Box sx={styles.fullWidth}>
                        <CustomInput
                            name="phone"
                            value={formik.values.phone}
                            onChange={(e) => {
                                const re = /^[+0-9\b]+$/;

                                // if value is not blank, then test the regex
                                if (e.target.value === '' || re.test(e.target.value)) {
                                    formik.handleChange(e); // Update Formik state with the new value
                                }
                            }}
                            onBlur={formik.handleBlur}
                            helperText={formik.errors.phone}
                            error={Boolean(formik.errors.phone)}
                            label={formatMessage({id: "phone"})}
                            color="secondary"
                            fullWidth
                            size="medium"
                        />

                    </Box>
                </Box>

                <Box
                    sx={styles.flexRow}
                >
                    <Box
                        sx={styles.fullWidth}
                        mr={4}
                    >
                        <CustomInput
                            name="country"
                            value={formik.values.country}
                            onChange={(e) => {
                                const re = /^[a-zA-Z]*$/;

                                if (e.target.value === '' || re.test(e.target.value)) {
                                    formik.handleChange(e); // Update Formik state with the new value
                                }
                            }}
                            onBlur={formik.handleBlur}
                            helperText={formik.errors.country}
                            error={Boolean(formik.errors.country)}
                            label={formatMessage({id: "country"})}
                            color="secondary"
                            fullWidth
                            size="medium"
                        />
                    </Box>
                    <Box
                        sx={styles.fullWidth}
                    >
                        <CustomInput
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={formik.errors.email}
                            error={Boolean(formik.errors.email)}
                            label={formatMessage({id: "email"})}
                            color="secondary"
                            fullWidth
                            size="medium"
                        />
                    </Box>
                </Box>

                <Box sx={{width: '100%', mb:4}}>
                    <CustomInput
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={formik.errors.password}
                        error={Boolean(formik.errors.password)}
                        placeholder={formatMessage({
                            id: 'password_confirm',
                        })}
                        color="secondary"
                        type={showPassword ? 'text' : 'password'}
                        label={formatMessage({id: "password"})}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={
                                            handleMouseDownPassword
                                        }
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                <Button
                    variant="contained"
                    size="large"
                    onClick={() => formik.handleSubmit()}
                >

                    {formatMessage({id: "createAccount"})}
                </Button>

            </Wrapper>
        </Box>
    )
}

export default SignupForm

const Wrapper = styled.form`
  background-color: #E4E5E8;
  border: 1px solid #C7C7C7;
  width: 600px;
  min-height: 450px;
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  text-align: left;
`

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`