import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Logo from '../../assets/img/Logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Typography, { TypographyProps } from "@mui/material/Typography";
import { LandingNavBarContentText } from "../../utils/constants";
import LanguageMenu from "../muiComponents/languageMenu";
import { useIntl } from "react-intl";
import { Link as LinkScroll, Events, scrollSpy } from "react-scroll";
import { useClicked } from '../../context/ClickedContext';

interface StyledTypographyProps extends TypographyProps {
    clicked: boolean;
}

const NavBar = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const { clicked, setClicked } = useClicked();

    const handleClick = (title: string) => {
        setClicked(title);
    };

    useEffect(() => {
        scrollSpy.update();

        Events.scrollEvent.register('begin', (to) => {
            setClicked(to);
        });

        Events.scrollEvent.register('end', (to) => {
            setClicked(to);
        });

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, [setClicked]);

    return (
        <Wrapper>
            <Container>
                <Link to="/">
                    <img width={118} height={68} src={Logo} alt={"logo"} />
                </Link>
                <FlexBox>
                    {LandingNavBarContentText.map(b => (
                        <LinkScroll
                            key={b.title}
                            activeClass="active"
                            to={b.title}
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={300}
                            onSetActive={() => handleClick(b.title)}
                        >
                            <StyledTypography
                                variant="caption"
                                color="text.secondary"
                                clicked={clicked === b.title}
                            >
                                {formatMessage({ id: b.title })}
                            </StyledTypography>
                        </LinkScroll>
                    ))}

                    <LanguageMenu />
                </FlexBox>

                <Button
                    size="medium"
                    variant={'outlined'}
                    color={"secondary"}
                    onClick={() => navigate('/login')}
                >
                    Login
                </Button>

            </Container>
        </Wrapper>
    );
};

export default NavBar;

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background: white;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: #929292 0 0 30px;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const Container = styled.div`
  max-width: 1340px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1380px) {
    padding: 0 20px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 65px;
`;

const StyledTypography = styled(({ clicked, ...props }: StyledTypographyProps) => (
    <Typography {...props} />
))`
  font-weight: 400;
  font-size: 15px;
  color: #212121;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  border-radius: 44px;

  //&:hover {
  //  color: #FFFFFF;
  //  transform: scale(1.05);
  //  background: #FF2727;
  //}

  ${({  clicked }) => clicked && `
    color: #212121;
    background: #FF2727;
    color: #FFFFFF;
    font-weight: 600;
  padding: 6px 12px;
    
  `}
`;
