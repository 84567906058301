import React from 'react'
import Navbar from "../../../components/navbar/NavBar";
import styled from "@emotion/styled";
import Footer from "../Footer/Footer";
import PrivacyAndPolicy from "./components/PrivacyAndPolicy";

const TermsAndConditions = () => {
    return (
        <Wrapper>
            <Navbar/>
            <Main>
                <PrivacyAndPolicy/>
            </Main>
            <Footer/>
        </Wrapper>
    )
}

export default TermsAndConditions

const Wrapper = styled.div`
  background-color: #F1F3F6;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Main = styled.main`
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px 40px;
`
