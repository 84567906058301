import React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle, IconButton, Typography,
} from '@mui/material'
import styled from "@emotion/styled";
import {X} from "@phosphor-icons/react";

const SuccessRequestACallDialog = ({open, handleClose}) => {


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
        >
            <DialogTitle sx={{backgroundColor: '#FAFAFA'}}>
                <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '28px',
                        color: '#4A4A4A',
                        textAlign: 'center',
                        m: "44px auto 12px"
                    }}
                >Call have been requested</Typography>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <X size={24} color="#525252"/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{backgroundColor: '#FAFAFA', p: "35px 100px"}}>
                <Typography
                    component="div"
                    sx={{
                        fontWeight: 300,
                        fontSize: '20px',
                        color: '#000000',
                        mb: 7
                    }}
                >
                    Your personal manager will contact you as soon as possible!
                </Typography>

                <StyledButton sx={{mb: 6}} color="secondary" variant="contained" fullWidth
                              size="large" onClick={handleClose}>
                    Ok
                </StyledButton>


            </DialogContent>
        </Dialog>
    )
}

export default SuccessRequestACallDialog

const StyledButton = styled(Button)`
  background-color: #292D34;
  color: #FFF;
  text-transform: none;
`
