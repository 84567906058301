import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ClickedContextProps {
    clicked: string | null;
    setClicked: (title: string | null) => void;
}

const ClickedContext = createContext<ClickedContextProps | undefined>(undefined);

interface ClickedProviderProps {
    children: ReactNode;
}

export const ClickedProvider: React.FC<ClickedProviderProps> = ({ children }) => {
    const [clicked, setClicked] = useState<string | null>(null);

    return (
        <ClickedContext.Provider value={{ clicked, setClicked }}>
            {children}
        </ClickedContext.Provider>
    );
};

export const useClicked = () => {
    const context = useContext(ClickedContext);
    if (context === undefined) {
        throw new Error('useClicked must be used within a ClickedProvider');
    }
    return context;
};
