import React from 'react'
import styled from "@emotion/styled";
import Box from "@mui/material/Box";

const TermsAndConditions = () => {
    return (
        <Box>
            <Title style={{marginTop: '20px'}}>
                Privacy Policy
            </Title>
            <ContentTitle>
                Privacy Policy
            </ContentTitle>
            <Content>
                BF Vinadeys Operations LLC (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the website
                bfvinadeys (the &quot;Site&quot;). This Privacy Policy outlines how we collect, use, and protect your
                personal<br/> information when you use our Site.<br/>
                By using our Site, you agree to the collection and use of information in accordance with this
                policy.<br/>
                Information Collection and Use<br/>
                We may collect several types of information for various purposes to provide and improve our services to
                you.<br/>
                Types of Information Collected<br/>
                <li>
                    Personal Information: While using our Site, we may ask you to provide us with certain personally
                    identifiable information that can be used to contact or identify you<br/> (&quot;Personal
                    Information&quot;). This may include, but is not limited to:
                    <li>
                        Name
                    </li>
                    <li>
                        Email address
                    </li>
                    <li>
                        Phone number
                    </li>
                </li>

                Use of Information<br/>
                We may use the collected information for various purposes, including but not limited to:<br/>
                <li>
                    Providing and maintaining our Site
                </li>
                <li>
                    Notifying you about changes to our Site or services
                </li>
                <li>
                    Providing customer support
                </li>
                <li>
                    Analyzing usage data to improve our Site and services
                </li>
                 <li>
                     Detecting, preventing, and addressing technical issues
                </li>
                Disclosure of Information<br/>
                We do not sell, trade, or otherwise transfer your Personal Information to outside parties. However, we
                may disclose your Personal Information in the following<br/> circumstances:<br/>
                <li>
                    To comply with legal obligations
                </li>
                 <li>
                     To protect and defend our rights or property
                </li>
                 <li>
                     To prevent or investigate possible wrongdoing in connection with the Site
                </li>
                <li>
                    To protect the personal safety of users of the Site or the public
                </li>
                <li>
                    To prevent or investigate possible wrongdoing in connection with the Site
                </li>
                <li>
                    In the event of a merger, acquisition, or sale of assets, your Personal Information may be transferred
                    as part of that transaction.
                </li>
                Data Security<br/>
                We take the security of your Personal Information seriously and take reasonable measures to protect it
                from loss, misuse, or unauthorized access. However, please be<br/> aware that no method of transmission over
                the internet or electronic storage is 100% secure and we cannot guarantee absolute security.<br/>
                Your Rights<br/>
                You have certain rights regarding your Personal Information, including the right to access, correct, or
                delete the information we hold about you. If you would like to<br/> exercise these rights, please contact us
                using the contact information provided below.<br/>
                Contact Us<br/>
                If you have any questions about this Privacy Policy, or if you would like to exercise your rights
                regarding your Personal Information, please contact us at<br/> policy@bfvinadeys.com.
                Changes to This Privacy Policy<br/>
                We may update our Privacy Policy from time to time. Any changes will be posted on this page with an
                updated &quot;Last Updated&quot; date. We encourage you to review this <br/>Privacy Policy periodically for
                any changes. Your continued use of the Site after any changes to this Privacy Policy constitutes your
                acceptance of such changes.
            </Content>
            <Title>
                Cookie Policy
            </Title>
            <Content style={{marginTop: '34px'}}>
                BF Vinadeys Operations LLC (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the website bfvinadeys (the &quot;Site&quot;). This Cookie Policy explains what cookies are, how we use them, and your<br/>
                choices regarding cookies when you visit our Site.<br/>
                By using our Site, you agree to the use of cookies in accordance with this policy.<br/>
                What Are Cookies?<br/>
                Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Site or a third-party to<br/>
                recognize you and make your next visit easier and more useful to you.<br/>
                How We Use Cookies<br/>
                When you use and access our Site, we may place a number of cookies files in your web browser. We use cookies for the following purposes:<br/>
                <li>To enable certain functions of the Site</li>
                <li>To provide analytics</li>
                <li>To store your preferences</li>
                <li>To personalize content and ads</li>
                <li>To provide social media features</li>
                <li>To authenticate users and prevent fraudulent activity</li>
                Types of Cookies We Use<br/>
                <li>Essential Cookies: These cookies are necessary for the Site to function properly. They enable core functionality such as security, network management, and accessibility. You cannot opt out of these cookies.</li>
                <li>Analytical/Performance Cookies: These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Site when they are using it. This helps us improve the way our Site works, for example, by ensuring that users are finding what they are looking for easily.</li>
                <li>Functionality Cookies: These cookies enable the Site to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages.</li>
                <li>Targeting/Advertising Cookies: These cookies are used to deliver advertisements that are relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns.</li>
                <li>Social Media Cookies: These cookies are set by a range of social media services that we have added to the Site to enable you to share our content with your friends and networks.</li>
                Your Choices Regarding Cookies<br/>
                You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from<br/>
                being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.<br/>
                Contact Us<br/>
                If you have any questions about our Cookie Policy, please contact us at policy@bfvinadeys.com.<br/>
                Changes to This Cookie Policy<br/>
                We may update our Cookie Policy from time to time. Any changes will be posted on this page with an updated &quot;Last Updated&quot; date. We encourage you to review this<br/>
                Cookie Policy periodically for any changes. Your continued use of the Site after any changes to this Cookie Policy constitutes your acceptance of such changes.
            </Content>

            <Title>
                Terms and Conditions
            </Title>
            <Content style={{marginTop: '34px', marginBottom: '80px'}}>
                Please read these Terms and Conditions (&quot;Terms&quot;, &quot;Terms and Conditions&quot;) carefully before using the website [www.bfvinadeys.com] (the &quot;Site&quot;) operated by BF Vinadeys<br/> Operations LLC (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).<br/>
                Your access to and use of the Site is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who<br/> access or use the Site.<br/>
                By accessing or using the Site you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Site.<br/>
                Intellectual Property<br/>
                The Site and its original content, features, and functionality are owned by BF Vinadeys Operations LLC and are protected by international copyright, trademark, patent,<br/> trade secret, and other intellectual property or proprietary rights laws.<br/>
                Links To Other Web Sites<br/>
                Our Site may contain links to third-party web sites or services that are not owned or controlled by BF Vinadeys Operations LLC.<br/>
                BF Vinadeys Operations LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You<br/> further acknowledge and agree that BF Vinadeys Operations LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be<br/> caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such web sites or services.<br/>
                Governing Law<br/>
                These Terms shall be governed and construed in accordance with the laws of [State], United States, without regard to its conflict of law provisions.<br/>
                Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or<br/> unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Site, and<br/> supersede and replace any prior agreements we might have between us regarding the Site.<br/>
                Changes<br/>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days&apos; notice prior to any<br/> new terms taking effect. What constitutes a material change will be determined at our sole discretion.<br/>
                By continuing to access or use our Site after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please<br/> stop using the Site.
                Contact Us<br/>
                If you have any questions about these Terms, please contact us at policy@bfvinadeys.com.<br/>
                By accessing or using the Site, you agree to be bound by these Terms and Conditions.

            </Content>
        </Box>
    )
}

export default TermsAndConditions

const Title = styled.h2`
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: #484848;
  text-align: center;
  margin-top: 116px;
`
const ContentTitle = styled.h5`
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  color: #484848;
  margin-top: 22px;
  margin-bottom: 14px;
`
const Content = styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  color: #484848;
`