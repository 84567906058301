import _ from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import {
    Button,
    TableHead,
} from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { fakeDealsAndHistoryDate} from "../../../../utils/constants";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";

const AccountTable = () => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const columns = [
        { id: 'deal-type', value: 'dealType' },
        {
            id: 'product',
            value: formatMessage({ id: 'product' }),
        },
        {
            id: 'quantity',
            value: formatMessage({ id: 'quantity' }),
        },
        {
            id: 'price',
            value: formatMessage({ id: 'price' }),
        },
        {
            id: 'complete-date',
            value: formatMessage({ id: 'completeDate' }),
        },
        {
            id: 'total-amount',
            value: formatMessage({ id: 'totalAmount' }),
        },
    ]




    return (
        <Box>

            <TableContainer
                component={Paper}
                sx={{ border: 1, borderColor: '#C7C7C7', borderRadius: 2 }}
                elevation={0}
            >
                <Table
                    sx={{ minWidth: 500 }}
                    aria-label="custom pagination table"
                >
                    <TableHead
                        sx={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <TableRow>
                            {columns?.map((col) => {
                                    return (
                                        <TableCell
                                            component="th"
                                            scope="col"
                                            key={_.uniqueId('th')}
                                            sx={{
                                                color: '#525252',
                                                textTransform: 'uppercase',
                                                fontWeight: 700,
                                                backgroundColor: "#E4E9F0"
                                            }}
                                        >
                                            {formatMessage({id: col.value})}
                                        </TableCell>
                                    )
                            })}
                        </TableRow>
                    </TableHead>
                    {fakeDealsAndHistoryDate.length ? (
                        <TableBody sx={{ whiteSpace: 'nowrap' }}>
                            {fakeDealsAndHistoryDate.map((row, index) => (
                                <TableRow
                                    key={_.uniqueId('row')}
                                >
                                    <TableCell
                                        id="deal-type"
                                        sx={{ width: '20px' }}
                                    >
                                        {row.dealType}
                                    </TableCell>

                                    <TableCell id="product">
                                        {row.product}
                                    </TableCell>

                                    <TableCell id="quantity">{row.quantity}</TableCell>

                                    <TableCell id="price">
                                        {row.price}
                                    </TableCell>

                                    <TableCell id="complete-date">
                                        {row.completeDate}
                                    </TableCell>

                                    <TableCell id="total-amount">
                                        {row.totalAmount}
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    colSpan={10}
                                >
                                    {formatMessage({ id: 'noData' })}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>

            <StyledButton
                onClick={() => navigate('/trade-history')}
                sx={{mt:4}}>Check my deals and trade history </StyledButton>
        </Box>
    )
}

export default AccountTable

const StyledButton = styled(Button)`
background-color: #FAFAFA;
  border: 1px solid #000000;
  border-radius: 100px;
  color: #000000;
`