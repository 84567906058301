import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {useIntl} from "react-intl";
import {WhyUsContentText} from "../../../utils/constants";
import {Box} from "@mui/system";
import wineImg from '../../../assets/img/Landing/Intro/why-us-img-of-wine.jpeg'

const WhyUs = () => {
    const {formatMessage} = useIntl()
    return (
        <IntroWrapper id="aboutWineInvesting">
                <FlexBox>
                    <Box style={{marginLeft: '60px'}}>


                        <Typography
                            variant="h1"
                            component="div"
                            color="text.secondary"
                            sx={{
                                fontWeight: 700,
                                fontSize: '72px',
                                color: '#393939',
                                mt: '80px',
                                mb: 5
                            }}
                        >
                            {formatMessage({id: "whyChooseUs"})}
                        </Typography>
                        <LiWrapper>
                            {WhyUsContentText.map((b, index) => {
                                return (
                                    <Box key={index}>
                                        <StyledContentText style={{fontWeight: 700}}>
                                            {formatMessage({id: b.title})}
                                        </StyledContentText>
                                        <StyledContentText>
                                            {formatMessage({id: b.content})}
                                        </StyledContentText>
                                    </Box>

                                )
                            })}
                        </LiWrapper>
                    </Box>

                    <StyledImg src={wineImg}/>
                </FlexBox>

        </IntroWrapper>
    )
}

export default WhyUs

const IntroWrapper = styled.div`
  width: 100%;
  min-width: 100vh;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  margin-bottom: 100px;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  min-width: 100vh;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`


const StyledImg = styled.img`
  min-width: 470px;
  min-height: 470px;
  margin-right: 60px;
`

const StyledContentText = styled.p`
  list-style-type: disc;
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
`
const LiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 70vh;
`
