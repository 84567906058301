import { createApi } from '@reduxjs/toolkit/dist/query/react'
import baseQueryWithIam from '../../app/baseQueryWithIam'
import {BalanceHistoryState, TradeHistoryState} from "../types";

export const balanceHistoryApiSlice = createApi({
    reducerPath: 'balanceHistoryApi',
    baseQuery: baseQueryWithIam,
    tagTypes: ['BalanceHistory'],
    endpoints: (build) => ({
        fetchBalance: build.query<BalanceHistoryState, void>({
            query: () => ({
                url: `/v1/myBalanceHistory`,
            }),
            transformResponse: (response: BalanceHistoryState) => response
        }),
        fetchTrade: build.query<TradeHistoryState, void>({
            query: () => ({
                url: `/v1/myTradeHistory`,
            }),
            transformResponse: (response: TradeHistoryState) => response
        })
    }),
})
