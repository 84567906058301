import { createApi } from '@reduxjs/toolkit/dist/query/react'
import baseQueryWithIam from '../../app/baseQueryWithIam'
import {AccountState, ChangePasswordState} from "../types";


export const accountApiSlice = createApi({
    reducerPath: 'accountApi',
    baseQuery: baseQueryWithIam,
    tagTypes: ['Account'],
    endpoints: (build) => ({
        fetchAccount: build.query<AccountState, void>({
            query: () => ({
                url: `/v1/myAccount`,
            }),
            transformResponse: (response: AccountState) => response
        }),
        changePassword: build.mutation<number, ChangePasswordState>({
            query: (password) => ({
                url: '/v1/changePassword',
                method: 'POST',
                body: password,
            }),
            invalidatesTags: [{ type: 'Account', id: 'LIST' }],
            transformResponse: (response: any) => response,
        }),
    }),
})
