import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {BenefitsContent} from "../../../utils/constants";
import {useIntl} from "react-intl";

const Benefits = () => {
    const { formatMessage } = useIntl()

    return (
        <IntroWrapper id="benefits">
            <FlexBox>
                {BenefitsContent.map((b,index) => {
                    return (
                        <StyledBox key={index}>
                            <img alt={"benefit-img"} src={b.img} width={66}/>
                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 300,
                                    fontSize: '20px',
                                    color: '#525252',
                                }}
                            >
                                {formatMessage({id: b.title})}
                            </Typography>
                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 300,
                                    fontSize: '20px',
                                    color: '#525252',
                                }}
                            >
                                {formatMessage({id: b.content})}
                            </Typography>
                        </StyledBox>

                    )
                })}
            </FlexBox>
        </IntroWrapper>
    )
}

export default Benefits

const IntroWrapper = styled.div`
  width: 100%;
  min-width: 100vh;
  height: 100%;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
`
const FlexBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-around;
  width: 100%;
  padding: 80px 0;
`

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  padding: 60px;
`