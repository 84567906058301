import { createApi } from '@reduxjs/toolkit/dist/query/react'
import baseQueryWithIam from '../../app/baseQueryWithIam'

export const fundsApiSlice = createApi({
    reducerPath: 'fundsApi',
    baseQuery: baseQueryWithIam,
    tagTypes: ['Funds'],
    endpoints: (build) => ({
        fetchFunds: build.query<any, void>({
            query: () => ({
                url: `/v1/funds`,
            }),
            transformResponse: (response: any) => response
        }),
    }),
})
