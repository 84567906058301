import React, {useState} from 'react'
import Navbar from "../../../components/navbar/NavBar";
import styled from "@emotion/styled";
import {H4} from "../../../layouts/TextStyles";
import {Accordion, AccordionDetails, AccordionSummary, Button, LinearProgress, Typography} from "@mui/material";
import {CaretDown} from "@phosphor-icons/react";
import {Box} from "@mui/system";
import SupportDialog from "./components/SupportDialog";
import {useIntl} from "react-intl";
import {FaqsState, ManagerState} from "../../../features/types";
import {faqsApiSlice} from "../../../features/faqs/faqsSlice";
import RequestACallDialog from "./components/RequestACallDialog";
import Footer from "../Footer/Footer";
import { useAppSelector } from '../../../app/hooks'
import { getValueFromLocalizedString, lang } from '../../../features/user/userSlice'

const Support = () => {
    const {formatMessage} = useIntl()

    const language = useAppSelector(lang)

    const {data: data = {} as ManagerState, isSuccess} = faqsApiSlice.useFetchMyManagerQuery()
    const {data: faqsData = {} as FaqsState, isFetching} = faqsApiSlice.useFetchFaqsQuery()
    const [openDialog, setOpenDialog] = useState(false)
    const [openRequestACallDialog, setOpenRequestACallDialog] = useState(false)
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleOpenRequestACallDialog = () => {
        setOpenRequestACallDialog(true);
    };


    return (

        <Wrapper>
            <Navbar/>
            <Main>
                {isFetching ? (
                    <LinearProgress/>
                ) : (
                    <>
                        <Box>
                            <H4 style={{marginBottom: '16px', textDecoration: 'underline'}}>Support and FAQ</H4>

                            {faqsData?.items?.map((b) => (
                                <Accordion
                                    style={{backgroundColor: "#FFF", boxShadow: "none", marginBottom: '16px'}}
                                    key={b._id}
                                    sx={{"&.MuiAccordion-root:before": {backgroundColor: "rgba(0, 0, 0, 0)"}}}
                                >
                                    <AccordionSummary
                                        sx={{border: "none"}}
                                        expandIcon={<CaretDown size={24} color="#B2B2B2"/>}
                                    >
                                        <Typography
                                            variant="h5"
                                            component="div"
                                            color="text.secondary"
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '24px',
                                                color: '#525252',
                                            }}
                                        >{getValueFromLocalizedString(b.localizedQuestion, language)}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{backgroundColor: "#F1F3F6"}}>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="text.secondary"
                                            sx={{
                                                fontWeight: 300,
                                                fontSize: '20px',
                                                color: '#525252',
                                            }}
                                        >
                                            {getValueFromLocalizedString(b.localizedAnswer, language)}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>

                        <Box sx={{mt: 8}}>
                            <H4 style={{
                                marginBottom: '16px',
                                textDecoration: 'underline'
                            }}>{formatMessage({id: "personalManager"})}</H4>

                            {isSuccess  ? (
                                <CardWrapper>
                                    <Typography
                                        component="div"
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '26px',
                                            color: '#000000',
                                            mb: '20px'
                                        }}
                                    >{data?.manager?.fullName} </Typography>
                                    <FlexBox>
                                        <StyledImg src={data?.manager?.imageUrl}/>
                                        <Box sx={{ml: '30px'}}>
                                            <Box sx={{mb: '24px'}}>
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        fontWeight: 700,
                                                        fontSize: '22px',
                                                        color: '#000000',
                                                        mb: "8px"
                                                    }}
                                                >
                                                    {data?.manager?.phone}
                                                </Typography>
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: '20px',
                                                        color: '#000000',
                                                    }}
                                                >
                                                    {data?.manager?.description}
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <StyledButton sx={{mr: '20px'}} variant="outlined" color="secondary"
                                                              size="medium" onClick={() => handleOpenDialog()}>
                                                    {formatMessage({id: "leaveAMessage"})}
                                                </StyledButton>
                                                <StyledButton variant="outlined" color="secondary" size="medium"
                                                              onClick={() => handleOpenRequestACallDialog()}>
                                                    {formatMessage({id: "requestACall"})}
                                                </StyledButton>

                                            </Box>

                                        </Box>

                                    </FlexBox>
                                </CardWrapper>

                            ) : (
                                <Typography
                                component="div"
                                sx={{
                                fontWeight: 400,
                                fontSize: '26px',
                                color: '#000000',
                                mb: '20px'
                            }}
                        > Your manager will be here soon..
                        </Typography>

                            )
                            }
                        </Box>
                    </>
                )}
                <SupportDialog
                    open={openDialog}
                    handleClose={() => setOpenDialog(false)}
                />

                <RequestACallDialog
                    open={openRequestACallDialog}
                    handleClose={() => setOpenRequestACallDialog(false)}
                />

            </Main>

            <Footer/>

        </Wrapper>


    )
}

export default Support

const Wrapper = styled.div`
  background-color: #F1F3F6;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Main = styled.main`
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px 40px;
`

const CardWrapper = styled.div`
  background-color: #E2E7EE;
  border: 1px solid #A2A2A2;
  border-radius: 4px;
  padding: 20px;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`

const StyledImg = styled.img`
  max-width: 110px;
  height: 110px;
  object-fit: cover;
  border-radius: 100px;
`
const StyledButton = styled(Button)`
  border-color: #000;
  background-color: #FFF;
  color: #000;
  text-transform: capitalize;
`
