
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger'
import userReducer from '../features/user/userSlice'
import {accountApiSlice} from "../features/account/accountSlice";
import {balanceHistoryApiSlice} from "../features/balanceHistory/balanceHistorySlice";
import {wineBottlesApiSlice} from "../features/wineBottles/wineBottlesSlice";
import {myPortfolioApiSlice} from "../features/myPortfolio/myPortfolio";
import {myBottlesApiSlice} from "../features/myBottles/myBottlesSlice";
import {faqsApiSlice} from "../features/faqs/faqsSlice";
import {newsApiSlice} from "../features/news/newsSlice";
import {fundsApiSlice} from "../features/funds/fundsSlice";
import {userRegisterSlice} from "../features/user/userRegisterSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        [accountApiSlice.reducerPath]: accountApiSlice.reducer,
        [balanceHistoryApiSlice.reducerPath]: balanceHistoryApiSlice.reducer,
        [wineBottlesApiSlice.reducerPath]: wineBottlesApiSlice.reducer,
        [myPortfolioApiSlice.reducerPath]: myPortfolioApiSlice.reducer,
        [myBottlesApiSlice.reducerPath]: myBottlesApiSlice.reducer,
        [faqsApiSlice.reducerPath]: faqsApiSlice.reducer,
        [newsApiSlice.reducerPath]: newsApiSlice.reducer,
        [fundsApiSlice.reducerPath]: fundsApiSlice.reducer,
        [userRegisterSlice.reducerPath]: userRegisterSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            accountApiSlice.middleware,
            balanceHistoryApiSlice.middleware,
            wineBottlesApiSlice.middleware,
            myPortfolioApiSlice.middleware,
            myBottlesApiSlice.middleware,
            faqsApiSlice.middleware,
            newsApiSlice.middleware,
            fundsApiSlice.middleware,
            userRegisterSlice.middleware,
            rtkQueryErrorLogger,
        ]),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
