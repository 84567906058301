import React, {useEffect, useState} from 'react'
import styled from "@emotion/styled";
import {Button,  Typography} from "@mui/material";
import { H4, } from "../../../../layouts/TextStyles";
import {Box} from "@mui/system";
import {useIntl} from "react-intl";
import {BottleFiltersState, FilteredBottlesState, WineBottlesState} from "../../../../features/types";
import {wineBottlesApiSlice} from "../../../../features/wineBottles/wineBottlesSlice";
import SingleSelect from "../../../../components/muiComponents/singleSelect";
import {useFormik} from "formik";
import {SelectChangeEvent} from "@mui/material/Select";
import SellInvestmentsDialog from "../../Portfolio/components/SellInvestmentsDialog";
import BuyInvestmentsDialog from "./BuyInvestmentsDialog";


const WineCollection = () => {
    const {formatMessage} = useIntl()
    const [getBottlesData, setGetBottlesData] = useState<FilteredBottlesState>()
    const {
        data: data = {} as WineBottlesState
    } = wineBottlesApiSlice.useFetchWineBottlesQuery()

    const content = [
        {
            title: "separateBottles",
            desc: "separateBottlesContentDescription"
        }
    ]

    const [filterBottle] = wineBottlesApiSlice.useFilterBottlesMutation()

    const initialFilterBottle: BottleFiltersState = {
        filters: {
            country: data?.defaultFilter?.country,
            color: data?.defaultFilter?.color,
            wineSort: data?.defaultFilter?.wineSort,
            price: data?.defaultFilter?.price,
            year: data?.defaultFilter?.year
        }
    }

    const handleFilterBottle = async () => {
        try {
            const response = await filterBottle({
                ...formik.values,
            }).unwrap()
            setGetBottlesData(response)
            // toast.success(formatMessage({id: 'changedSuccess'}))
        } catch (error) {
            // toast.error((formatMessage({id: 'errorInChangingPassword'})))
        }
    };

    const formik = useFormik({
        initialValues: initialFilterBottle,
        // validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: async () => {
            handleFilterBottle()
        },
    })

    const handleSelectChange = (fieldName: keyof BottleFiltersState['filters']) => (
        event: SelectChangeEvent<typeof formik.values.filters[keyof typeof formik.values.filters]>
    ) => {
        const {
            target: {value},
        } = event;
        formik.setFieldValue(`filters.${fieldName}`, value);
        formik.submitForm();
    };

    useEffect(() => {
        handleFilterBottle();
    }, []);


    const [selectedData, setSelectedData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false)
    const handleOpenDialog = (data) => {
        setSelectedData(data);
        setOpenDialog(true);
    };


    return (
        <Box>
            <H4 style={{
                marginBottom: '16px',
                textDecoration: 'underline'
            }}>  {formatMessage({id: content[0].title})}</H4>
            <Typography
                variant="h5"
                component="div"
                color="text.secondary"
                sx={{
                    fontWeight: 400,
                    fontSize: '20px',
                    color: '##525252',
                    mb: '24px'
                }}
            >
                {formatMessage({id: content[0].desc})}
            </Typography>

            <FlexBox style={{
                backgroundColor: '#fff',
                borderRadius: '4px',
                border: '1px solid #97A4B7',
                height: '50px',
                marginBottom: '30px'
            }}>
                <StyledFilter>
                    Filters
                </StyledFilter>

                <FlexBox style={{justifyContent: "space-around", width: '100%'}}>
                    <FlexBox style={{gap: '16px', alignItems: 'center'}}>
                        <StyledFilterTitle>
                            Country:
                        </StyledFilterTitle>
                        <SingleSelect
                            name='country'
                            value={formik.values?.filters?.country}
                            handleChange={handleSelectChange('country')}
                            menuItems={[
                                { id: null, title: 'None' },
                                ...(data?.countries?.map(b => ({ id: b, title: b })) || [])
                            ]}
                            small
                            handleBlur={formik.handleBlur}
                        />
                    </FlexBox>
                    <FlexBox style={{gap: '16px', alignItems: 'center'}}>
                        <StyledFilterTitle>
                            Color:
                        </StyledFilterTitle>

                        <SingleSelect
                            name='color'
                            value={formik.values?.filters?.color}
                            handleChange={handleSelectChange('color')}
                            menuItems={[
                                { id: null, title: 'None' },
                                ...(data?.colors?.map(b => ({ id: b, title: b })) || [])
                            ]}                            small
                            handleBlur={formik.handleBlur}
                        />
                    </FlexBox>

                    <FlexBox style={{gap: '16px', alignItems: 'center'}}>
                        <StyledFilterTitle>
                            Wine sort:
                        </StyledFilterTitle>

                        <SingleSelect
                            name='wineSort'
                            value={formik.values?.filters?.wineSort}
                            handleChange={handleSelectChange('wineSort')}
                            menuItems={[
                                { id: null, title: 'None' },
                                ...(data?.wineSorts?.map(b => ({ id: b, title: b })) || [])
                            ]}                            small
                            handleBlur={formik.handleBlur}
                        />
                    </FlexBox>

                    <FlexBox style={{gap: '16px', alignItems: 'center'}}>
                        <StyledFilterTitle>
                            Price:
                        </StyledFilterTitle>

                        <SingleSelect
                            name='price'
                            value={formik.values?.filters?.price}
                            handleChange={handleSelectChange('price')}
                            menuItems={[
                                { id: null, title: 'None' },
                                ...(data?.prices?.map(b => ({ id: b, title: b })) || [])
                            ]}
                            small
                            handleBlur={formik.handleBlur}
                        />
                    </FlexBox>

                    <FlexBox style={{gap: '16px', alignItems: 'center'}}>
                        <StyledFilterTitle>
                            Vintage:
                        </StyledFilterTitle>

                        <SingleSelect
                            name='year'
                            value={formik.values?.filters?.year}
                            handleChange={handleSelectChange('year')}
                            menuItems={[
                                { id: null, title: 'None' },
                                ...(data?.years?.map(b => ({ id: b, title: b })) || [])
                            ]}
                            small
                            handleBlur={formik.handleBlur}
                        />
                    </FlexBox>
                </FlexBox>

            </FlexBox>

            <GridBox>
                {getBottlesData?.bottles?.map(b => {
                    return (
                        <WineWrapper key={b.id}>
                            <Box sx={{display: 'flex', justifyContent: "center", mb: '24px'}}>
                                <img
                                    alt="wine"
                                    src={b.imageUrl}
                                    width={210}
                                    height={210}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '210px',
                                        maxHeight: '210px',
                                    }}
                                />                            
                            </Box>
                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '20px',
                                    color: '##525252',
                                    mb: '8px'
                                }}
                            >
                                {b.title}
                            </Typography>
                            <FlexBox>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '20px',
                                        color: '##525252',
                                    }}
                                >
                                    {b.country.title}
                                </Typography>
                                <img alt="flag" src={b.country.imageUrl} width={32}/>
                            </FlexBox>

                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '##525252',
                                    mb: '8px'

                                }}
                            >
                                {formatMessage({id: "color"})}: <AnswerText>{b.color}</AnswerText>
                            </Typography>

                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '##525252',
                                    mb: '8px'
                                }}
                            >
                                {formatMessage({id: "wineType"})}: <AnswerText>Antinori</AnswerText>
                            </Typography>

                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '##525252',
                                    mb: '8px'
                                }}
                            >
                                {formatMessage({id: "lastPrice"})}: <AnswerText>{b?.price}</AnswerText>
                            </Typography>

                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '##525252',
                                    mb: '8px'
                                }}
                            >
                                {formatMessage({id: "volume"})}: <AnswerText>{b.volume}</AnswerText>
                            </Typography>

                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '##525252',
                                    mb: '8px'
                                }}
                            >
                                {formatMessage({id: "bottlesIncluded"})}: <AnswerText>{b.bottlesIncluded}</AnswerText>
                            </Typography>

                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '##525252',
                                    mb: '8px'
                                }}
                            >
                                {/*{formatMessage({id: "lastMonthPrice"})}: <AnswerText>{b.priceChanges[0]}</AnswerText>*/}
                                {/*<img alt="arrow-down" width={18} src={''}/>*/}
                            </Typography>

                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '##525252',
                                    mb: '8px'
                                }}
                            >
                                {/*{formatMessage({id: "last3MonthsPrice"})}: <AnswerText>{b.priceChanges[1]}</AnswerText>*/}
                                {/*<img alt="arrow-top" width={18} src={''}/>*/}
                            </Typography>

                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '##525252',
                                    mb: '24px'
                                }}
                            >
                                {/*{formatMessage({id: "last12MonthsPrice"})}: <AnswerText>{b.priceChanges[2]}</AnswerText>*/}
                                {/*<img alt="arrow-top" width={18} src={''}/>*/}
                            </Typography>
                            <Button sx={{fontSize: "13px"}} variant="contained"
                                    onClick={() => handleOpenDialog(b)}
                                    fullWidth>{formatMessage({id: "addToInvestment"})}</Button>

                        </WineWrapper>
                    )
                })}
            </GridBox>

            <BuyInvestmentsDialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                data={selectedData}
            />

        </Box>


    )
}

export default WineCollection


const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`
const WineWrapper = styled.div`
  background-color: #FFF;
  padding: 20px;
  border-radius: 8px;

`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px
`
const AnswerText = styled.span`
  font-size: 18px;
  font-weight: 400;
`

const StyledFilter = styled(Box)`
  background-color: #566A87;
  color: #FFF;
  width: 100px;
  height: 50px;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledFilterTitle = styled.h5`
  font-size: 18px;
  font-weight: 600;
  color: #525252
`
