import { createApi } from '@reduxjs/toolkit/dist/query/react'
import baseQueryWithIam from '../../app/baseQueryWithIam'
import {BottleFiltersState, FilteredBottlesState, WineBottlesState} from "../types";

export const wineBottlesApiSlice = createApi({
    reducerPath: 'wineBottlesApi',
    baseQuery: baseQueryWithIam,
    tagTypes: ['WineBottle'],
    endpoints: (build) => ({
        fetchWineBottles: build.query<WineBottlesState, void>({
            query: () => ({
                url: `/v1/bottleFilters`,
            }),
            transformResponse: (response: WineBottlesState) => response
        }),
        filterBottles: build.mutation<FilteredBottlesState, BottleFiltersState>({
            query: (bottle) => ({
                url: '/v1/getBottles',
                method: 'POST',
                body: bottle,
            }),
            invalidatesTags: [{ type: 'WineBottle', id: 'LIST' }],
            transformResponse: (response: FilteredBottlesState) => response,
        }),
    }),
})
