import React from 'react'
import Navbar from "../../../components/navbar/NavBar";
import styled from "@emotion/styled";
import AccountInfo from "./components/AccountInfo";
import Footer from "../Footer/Footer";

const Account = () => {

    return (
        <Wrapper >
            <Navbar/>
            <Main >
                <AccountInfo/>
            </Main>
            <Footer/>
        </Wrapper>

    )
}

export default Account

const Wrapper = styled.div`
  background-color: #F1F3F6;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Main = styled.main`
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px 40px ;
`

