import React from "react";
import styled from "@emotion/styled";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import { FooterImg, FooterLinkText, LandingNavBarContentText} from "../../../utils/constants";
import PaymentMethods from "../../../assets/img/Landing/Intro/payment-methods.png"
import {useIntl} from "react-intl";
import {Link as LinkScroll} from "react-scroll";
import {useNavigate} from "react-router-dom";

const LandingFooter = () => {
    const {formatMessage} = useIntl()
    const navigate = useNavigate()


    return (
        <Box>
            <FooterContentWrapper>
                <GridBox>
                    {LandingNavBarContentText.map(b => (
                        <LinkScroll
                            key={b.title}
                            activeClass="active"
                            to={b.title}
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={300}
                        >
                            <Typography
                                key={b.title}
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '20px',
                                    color: '#F0F0F0',
                                }}
                            >
                                {formatMessage({ id: b.title })}
                            </Typography>
                        </LinkScroll>

                    ))}
                </GridBox>
                <GridBox>
                    <Box sx={{mt: '70px'}}>
                        <Typography
                            variant="h5"
                            component="div"
                            color="text.secondary"
                            sx={{
                                fontWeight: 700,
                                fontSize: '20px',
                                color: '#FFF',
                            }}
                        >
                            {formatMessage({id: "wineTxFund"})}
                        </Typography>
                        <Box sx={{mt: '30px', gap: '12px', display: 'flex', flexDirection: 'column'}}>
                            {FooterLinkText.map(b => {
                                return (
                                    <Typography
                                        key={b.title}
                                        variant="h5"
                                        component="div"
                                        color="text.secondary"
                                        onClick={() => navigate('/terms-and-conditions')}
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '20px',
                                            color: '#FFF',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {formatMessage({id: b.title})}
                                    </Typography>
                                )
                            })}

                        </Box>
                    </Box>
                    <Box sx={{mt: '70px'}}>
                        <Typography
                            variant="h5"
                            component="div"
                            color="text.secondary"
                            sx={{
                                fontWeight: 700,
                                fontSize: '20px',
                                color: '#FFF',
                                position: 'relative'
                            }}
                        >
                            {formatMessage({id: "paymentMethods"})}
                            <img alt="payment-methods" src={PaymentMethods} height={53} style={{position: "absolute", top: '45px', left: 0}}/>
                        </Typography>


                    </Box>
                </GridBox>

                <FlexBox>
                    {FooterImg.map(b => {
                            return (
                                <img alt="medals" height={78} key={b.img} src={b.img}/>
                            )
                        }
                    )}

                </FlexBox>

            </FooterContentWrapper>
            <FooterInfoWrapper>
                <Typography
                    variant="h5"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 400,
                        fontSize: '20px',
                        color: '#9F9F9F',
                    }}
                >
                    {formatMessage({id: "footerAllRightReserved"})}
                </Typography>
            </FooterInfoWrapper>
        </Box>
    )
}

export default LandingFooter

const FooterContentWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: #292D34;
  overflow-y: hidden;
  padding: 60px;
  position: relative;
`
const FooterInfoWrapper = styled.div`
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #131519;
  padding: 50px 50px;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: start;
  width: 100%;
  gap: 40px;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:20px;
  position: absolute;
  right: 240px;
  bottom:120px;
`