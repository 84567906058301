import React from 'react';
import styled from '@emotion/styled';
import AnimatedWine from '../../assets/img/AnimatedWine.mp4';
import Logo from '../../assets/img/Logo.png';
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";

const Loader = () => {
    return (
        <LoaderWrapper>
            <FlexBox>

            <img src={Logo} alt="logo" height={70} width={120}/>
                <Box style={{marginTop: '80px'}}>
                    <VideoWrapper>
                        <video autoPlay loop muted>
                            <source src={AnimatedWine} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </VideoWrapper>
                    <Typography
                        variant="h5"
                        component="div"
                        color="text.secondary"
                        sx={{
                            fontWeight: 400,
                            fontSize: '28px',
                            color: '#525252',
                            mb: '120px',
                            textAlign: 'center',
                            mt:'40px'
                        }}
                    >
                        Loading <AnimatedText/>
                    </Typography>
                </Box>

            </FlexBox>

        </LoaderWrapper>
    );
};

export default Loader;

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  
`;

const VideoWrapper = styled.div`
  width: 300px; /* Adjust the width and height as needed */
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    width: 100%;
    height: 100%;
  }
`;

const AnimatedText = styled.span`
    &::after {
        content: '';
        display: inline-block;
        animation: ellipsis steps(3, end) 1s infinite;
        width: 1em;
        text-align: left;
      
    }

    @keyframes ellipsis {
        0%, 100% {
            content: '...';
        }
        25% {
            content: '.';
        }
        50% {
            content: '..';
        }
        75% {
            content: '...';
        }
    }
`;
const FlexBox =styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
`