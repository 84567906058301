import React, {useState} from 'react'
import styled from '@emotion/styled'
import {useNavigate} from 'react-router-dom'
import {
    Button,
    InputAdornment,
    IconButton, Box,
} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import logo from '../../assets//img/Logo.png'
import {Body1, H3} from '../../layouts/TextStyles'
import {useAppDispatch, useAppSelector,} from '../../app/hooks'
import {resetError} from '../../features/user/userSlice'
import CustomInput from '../muiComponents/customInput'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import Arrow from "../../assets/img/back-arrow.png"
import {useIntl} from "react-intl";
import CustomLinearProgress from "../muiComponents/customLinearProgress";
import {userLogin} from "../../features/user/userActions";
import {toast} from "react-toastify";

const LoginForm = () => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {formatMessage} = useIntl()


    const { loading, userInfo, success, error } = useAppSelector(
        (state) => state.user,
    )

    const [loginInfo, setLoginInfo] = useState({email: '', password: ''})
    const [showPassword, setShowPassword] = useState(false)

    const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        try {
            await dispatch(userLogin(loginInfo)).unwrap()
            toast.success(formatMessage({ id: 'loggedSuccessfully' }))
            navigate('/account')

        } catch (error) {
            toast.error(formatMessage({ id: 'error' }))
        }
    }

    const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(resetError())
        setLoginInfo({...loginInfo, email: e.target.value})
    }

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(resetError())
        setLoginInfo({...loginInfo, password: e.target.value})
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault()
    }

    return (
        <Wrapper onSubmit={submitForm}>
            <FlexBox>
                <Box sx={{display: "flex", gap: '8px', alignItems: "center", cursor: 'pointer'}}  onClick={() => navigate('/')}>
                    <img src={Arrow} width={18} height={16} alt="arrow"/>
                    <Body1 color={theme.palette.secondary.main}>{formatMessage({id: "goBack"})}</Body1>
                </Box>
                <Body1 style={{cursor: 'pointer'}} color={theme.palette.secondary.main} onClick={() => navigate('/sign-up')}>{formatMessage({id: "registration"})}</Body1>

            </FlexBox>
            <img
                alt="logo"
                src={logo}
                style={{width: 170, height: 98, margin: '0 auto 12px'}}
            />

            <H3 color="#525252" style={{margin: '0 auto '}}>{formatMessage({id: "login"})}</H3>


            <CustomInput
                id="email"
                type="email"
                label={formatMessage({id: "emailOrPhoneNumber"})}
                color="secondary"
                onChange={handleName}
            />

            <CustomInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                label={formatMessage({id: "password"})}
                color="secondary"
                onChange={handlePassword}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? (
                                    <VisibilityOff/>
                                ) : (
                                    <Visibility/>
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <Button
                variant="contained"
                size="large"
                type="submit"
                sx={{mt:'24px'}}
            >
                {formatMessage({id: "login"})}
            </Button>

            {loading ? (
                <CustomLinearProgress
                    style={{ marginTop: '20px' }}
                    backgroundcolor="#daac24"
                    barbackgroundcolor="#F8EED3"
                />
            ) : (
                ''
            )}
        </Wrapper>
    )
}

export default LoginForm

const Wrapper = styled.form`
  background-color: #E4E5E8;
  border: 1px solid #C7C7C7;
  width: 500px;
  min-height: 450px;
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  text-align: left;
`

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`
