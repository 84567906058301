import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home/Home'
import Login from '../pages/Login'
import NotFound from '../pages/NotFound'
import ProtectedRoute from "../components/ProtectedRoute";
import Account from "../pages/user/Account/Account";
import TradeHistory from "../pages/user/TradeHistory/TradeHistory";
import BalanceHistory from "../pages/user/BalanceHistory/BalanceHistory";
import Support from "../pages/user/Support/Support";
import Trading from "../pages/user/Trading/Trading";
import Portfolio from "../pages/user/Portfolio/Portfolio";
import Verify from "../pages/user/Verify/Verify";
import TermsAndConditions from "../pages/user/TermsAndConditions/TermsAndConditions";

const AppRouter = () => {
    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/login"
                    element={<Login showLoginPage={true} />}
                />
                <Route
                    path="/sign-up"
                    element={<Login showLoginPage={false} />}
                />

                <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                />

                <Route
                    path="/verify/:verifyId"
                    element={<Verify />}
                />

                <Route element={<ProtectedRoute />}>
                    <Route
                        path="/account"
                        element={<Account />}
                    />

                    <Route
                        path="/trade-history"
                        element={<TradeHistory />}
                    />

                    <Route
                        path="/balance-history"
                        element={<BalanceHistory />}
                    />

                    <Route
                        path="/support"
                        element={<Support />}
                    />

                    <Route
                        path="/trading"
                        element={<Trading />}
                    />
                    <Route
                        path="/portfolio"
                        element={<Portfolio />}
                    />

                </Route>

                <Route
                    path="*"
                    element={<NotFound />}
                />
            </Routes>
        </>
    )
}

export default AppRouter
