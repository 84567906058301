import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {useIntl} from "react-intl";
import grapeImg from '../../../assets/img/Landing/Intro/grape.png'
import Investment from '../../../assets/img/Landing/Intro/investment.png'
import {Button} from "@mui/material";

const InvestmentAdvertisement = () => {
    const {formatMessage} = useIntl()
    return (
        <IntroWrapper>
            <FlexBox>
                <StyledImg src={grapeImg}/>
                <Typography
                    variant="h1"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '36px',
                        color: '#FFF',
                        textAlign: 'center'

                    }}
                >
                    {formatMessage({id: "investAdvertisementTitle"})}
                </Typography>
                <StyledInvestmentImg src={Investment}/>
                <Typography
                    variant="h1"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 300,
                        fontSize: '26px',
                        color: '#D9D9D9',
                        maxWidth: '1080px',
                        textAlign: 'center'

                    }}
                >
                    {formatMessage({id: "investAdvertisementContentText1"})}

                </Typography>
                <Typography
                    variant="h1"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 300,
                        fontSize: '26px',
                        color: '#D9D9D9',
                        maxWidth: '1080px',
                        textAlign: 'center'

                    }}
                >
                    {formatMessage({id: "investAdvertisementContentText2"})}

                </Typography>
                <Typography
                    variant="h1"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 400,
                        fontSize: '26px',
                        color: '#D9D9D9',
                        textDecoration: 'underline',
                        textAlign: 'center'

                    }}
                >
                    {formatMessage({id: "investAdvertisementContentLinkText"})}

                </Typography>
                <StyledButton
                    variant="contained"
                    size="large"
                >
                    {formatMessage({id: "registration"})}

                </StyledButton>

                <Typography
                    variant="h1"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '24px',
                        color: '#D9D9D9',
                        maxWidth: '520px',
                        textAlign: 'center'
                    }}
                >
                    {formatMessage({id: "investAdvertisementContentAdditionalText"})}

                </Typography>
            </FlexBox>
        </IntroWrapper>
    )
}

export default InvestmentAdvertisement

const IntroWrapper = styled.div`
  width: 100%;
  min-width: 100vh;
  height: 100%;
  min-height: 80vh;
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
  z-index: 4;
  background-color: #2C2D39;
  padding: 148px 0 120px;
  overflow-x: clip;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  gap: 48px;
  position: relative;
  z-index: 80;
`

const StyledImg = styled.img`
  position: absolute;
  height: 1200px;
  bottom: -250px;
  right: -700px;
  filter: brightness(0.8) contrast(1.2) grayscale(1.5);
  background-blend-mode: multiply;
  opacity: 70%;
  z-index: -1;
`

const StyledInvestmentImg = styled.img`
  width: 146px;
  height: 146px;
`
const StyledButton = styled(Button)`
  background-color: #F4EACA;
  color: #000;
  font-weight: 700;
  font-size: 18px;
  padding: 36px 80px;
  border-radius: 44px;

  &:hover {
    background-color: #F4EACA;
    color: #000;

  }
`