import React from 'react'
import { useTheme} from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {Checkbox, InputLabel, ListItemText} from '@mui/material'
import {CheckCircle, RadioButtonUncheckedOutlined} from '@mui/icons-material'
import {lang} from '../../../features/user/userSlice'
import {useAppSelector} from '../../../app/hooks'
import {capitalize} from '../../../utils/functions'
import {useIntl} from 'react-intl'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
        elevation: 2,
    },
}

export default function SingleSelect({
                                         id = '',
                                         name = '',
                                         value = null,
                                         placeholder = '',
                                         handleChange,
                                         handleBlur = null,
                                         handleError = null,
                                         menuItems = [],
                                         disabled = false,
                                         small = false,
                                         marginTop = 0,
                                         label = '',
                                     }) {
    const theme = useTheme()
    const language = useAppSelector(lang)
    const {formatMessage} = useIntl()


    return (
        <div>
            <FormControl
                sx={{
                    mt: marginTop,
                    width: '100%',
                    minWidth: '120px',
                    borderRadius: '8px',
                    boxShadow: 0,
                    height: '60px'
                }}
                size={small ? 'small' : 'medium'}
                variant={"standard"}
            >
                <InputLabel sx={{border: '10px'}} id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    id={id}
                    name={name}
                    displayEmpty
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                    label={label}
                    onBlur={handleBlur}
                    error={handleError}
                    inputProps={{
                        classes: {
                            notchedOutline: 'customOutlinedInput-notchedOutline',
                        },
                    }}
                    renderValue={(selected) => {
                        if (!selected) {
                            return (
                                <div style={{color: '#FFF'}}>
                                    {placeholder}
                                </div>
                            )
                        }

                        const result = menuItems.find(
                            (item) => item.id === selected,
                        )

                        return (
                            result?.name ||
                            result?.[`title${capitalize(language)}`] ||
                            result?.title ||
                            result?.firstName + ' ' + result?.lastName
                        )
                    }}
                    MenuProps={MenuProps}
                >
                    {menuItems?.map((item) => (
                        <MenuItem
                            key={item.id}
                            value={item.id}
                        >
                            <ListItemText
                                primary={
                                    item.name ||
                                    item[`title${capitalize(language)}`] ||
                                    item.title ||
                                    item.firstName + ' ' + item.lastName
                                }
                            />
                            <Checkbox
                                icon={<RadioButtonUncheckedOutlined/>}
                                checkedIcon={<CheckCircle/>}
                                checked={value === item.id}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
