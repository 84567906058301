import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from "@emotion/styled";

const withIntersectionObserver = (Component, animation) => {
    const WrappedComponent = (props) => {
        const { ref, inView } = useInView({
            triggerOnce: true,
            threshold: 0.3,
        });

        return (
            <AnimatedDiv ref={ref} inView={inView} animation={animation}>
                <Component {...props} />
            </AnimatedDiv>
        );
    };

    WrappedComponent.displayName = `WithIntersectionObserver(${Component.displayName || Component.name || 'Component'})`;

    return WrappedComponent;
};

const AnimatedDiv = styled.div`
  opacity: ${props => (props.inView ? 1 : 0)};
  transform: ${props => (props.inView ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 0.8s ease-out;
`;

export default withIntersectionObserver;
