import React from 'react';
import styled from "@emotion/styled";
import {Button, IconButton, InputAdornment} from "@mui/material";
import { H3 } from "../../../layouts/TextStyles";
import logo from "../../../assets/img/Logo.png";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { requiredString} from "../../../utils/validation";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import {userRegisterSlice, VerifyState} from "../../../features/user/userRegisterSlice";
import {useNavigate, useParams} from "react-router-dom";
import CustomInput from "../../../components/muiComponents/customInput";
import {Copy} from "@phosphor-icons/react";

const Verify = () => {
    const { formatMessage } = useIntl();
    const {verifyId} = useParams();
    const navigate = useNavigate()

    const [verify] = userRegisterSlice.useVerifyUserMutation()

    const verifyInitialValue: VerifyState = {
        userId: '',
        code: ''
    }

    const validationSchema = yup.object().shape({
        code: requiredString({formatMessage}),
    })

    const handleVerify = async () => {
        try {
            await verify({
                ...formik.values,
                userId: verifyId
            }).unwrap()
            toast.success(formatMessage({id: 'sendSuccess'}))
            navigate('/login')
        } catch (error) {
            toast.error((formatMessage({id: 'errorInSending'})))
        }
    };

    const formik = useFormik({
        initialValues: verifyInitialValue,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async () => {
            handleVerify()
        },
    })

    return (
        <BgWrapper>
            <Main>
                <Wrapper>
                    <img
                        alt="logo"
                        src={logo}
                        style={{ width: 170, height: 98, margin: '0 auto 12px' }}
                    />

                    <H3 color="#525252" style={{ margin: '0 auto 20px' }}>{formatMessage({ id: "verifyYourEmail" })}</H3>

                  <CustomInput
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.errors.code}
                  error={Boolean(formik.errors.code)}
                  placeholder={"Please, provide a code from your mail"}
                  InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                              <IconButton
                                  edge="end"
                                  onClick={() => {
                                      navigator.clipboard.readText().then(text => {
                                          // Remove newline characters from the copied text
                                          const cleanedText = text.trim().replace(/\n/g, '');
                                          formik.setFieldValue('code', cleanedText);
                                      });
                                  }}                            >
                                  <Copy size={24} />
                              </IconButton>
                          </InputAdornment>
                      ),
                  }}
                  />

                    <StyledButton
                        variant="contained"
                        size="large"
                        onClick={() => formik.handleSubmit()}
                    >
                        {formatMessage({ id: "submit" })}
                    </StyledButton>
                </Wrapper>
            </Main>
        </BgWrapper>
    );
};


export default Verify;


const Wrapper = styled.form`
  background-color: #E4E5E8;
  border: 1px solid #C7C7C7;
  width: 500px;
  min-height: 350px;
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-around;
`

const BgWrapper = styled.div`
  background: url(${require("../../../assets/img/login.png")});
  min-height: 100vh;
  height: 100%;
  background-size: cover;
display: flex;
  align-items: center;
  justify-content: center;

`

const Main = styled.main`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled(Button)`
  border-radius: 8px;
`

