import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'
import { logout, setCredentials } from '../features/user/userSlice'
import { REACT_APP_IAM_SERVICE_BASE_URL } from '../utils/constants'
import storage from '../utils/storageService'

// Create a new mutex
const mutex = new Mutex()

const baseQuery = fetchBaseQuery({
    baseUrl: REACT_APP_IAM_SERVICE_BASE_URL,
    prepareHeaders: (headers) => {
        const access = storage?.get('access')
        // If we have a token set in state, let's assume that we should be passing it.
        if (access) {
            headers.set('device-id', "werwer")
            headers.set('authorization', `Bearer ${access}`)
        }
        return headers
    },
})

const refreshQuery = fetchBaseQuery({
    baseUrl: REACT_APP_IAM_SERVICE_BASE_URL,
})

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)

    if ((result.error as any)?.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()

            try {
                const refreshResult: any = await refreshQuery(
                    {
                        credentials: 'include',
                        url: `/v1/refresh`,
                        method: 'POST',
                        headers: {"device-id": "werwer"},
                        body: { "refreshToken": storage?.get('refresh') },
                    },
                    api,
                    extraOptions,
                )

                if (refreshResult) {
                    api.dispatch(setCredentials(refreshResult))
                    // Retry the initial query
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(logout())
                }
            } finally {
                // release must be called once the mutex should be released again.
                release()
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }

    return result
}

export default baseQueryWithReauth
