import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {useIntl} from "react-intl";
import {HowItWorksContentText} from "../../../utils/constants";
import bgImg from "../../../assets/img/Landing/Intro/bg-of-how-it-works.png";
import {Box} from "@mui/system";

const HowItWorks = () => {
    const { formatMessage } = useIntl()

    return (
        <IntroWrapper id="howItWorks">
            <StyledBgImg src={bgImg}/>
            <StyledBox/>
            <Box sx={{zIndex: '4'}}>

            <Typography
                variant="h1"
                component="div"
                color="text.secondary"
                sx={{
                    fontWeight: 700,
                    fontSize: '72px',
                    color: '#FFF',
                    mb: '100px',
                    textShadow: '0px 0px 12px rgb(0 0 0 / 25%)',
                    textAlign: 'center',
                    mt:6
                }}
            >
                {formatMessage({id: "howItWorks"})}
            </Typography>
            <FlexBox>
                {HowItWorksContentText.map(b => {
                    return (
                        <ContentWrapper key={b.title}>
                            <CircledText>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '24px',
                                        color: '#23242D',
                                    }}
                                >
                                    { b.title }
                                </Typography>
                            </CircledText>
                            <Typography
                                variant="h5"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '24px',
                                    color: '#EEE9E8',
                                    textAlign: 'center',
                                    mt: '20px'
                                }}
                            >
                                {formatMessage({id: b.content})}
                            </Typography>
                        </ContentWrapper>

                    )
                })}
            </FlexBox>
            </Box>

        </IntroWrapper>
    )
}

export default HowItWorks

const IntroWrapper = styled.div`
  width: 100%;
  min-width: 100vh;
  height: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
  position: relative;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  min-width: 100vh;
  width: 100%;
  justify-content: space-around;
  gap: 20px;
  
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`


const CircledText = styled.div`
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledBgImg = styled.img`
  position: absolute;
  z-index: 1;
  top: -102px;
  right: 0;
  min-width: 100vh;
  width: 100%;
`
const StyledBox = styled(Box)`
  background: #D13A3A;
  width: 100%;
  height: 60%;
  position: absolute;
  bottom: 0;
`