import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {InvestmentList} from "../../../utils/constants";
import {useIntl} from "react-intl";

const WhyInvest = () => {
    const {formatMessage} = useIntl()
    return (
        <IntroWrapper>
            <FlexBox>
                <Typography
                    variant="h1"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: 700,
                        fontSize: '48px',
                        color: '#525252',
                        mt: '70px',
                        mb: '70px'
                    }}
                >
                    {formatMessage({id: "whyInvestTitle"})}
                </Typography>


                <StyledBox>
                    <Typography
                        variant="h1"
                        component="div"
                        color="text.secondary"
                        sx={{
                            fontWeight: 300,
                            fontSize: '20px',
                            color: '#525252',
                            mb: '50px'
                        }}
                    >
                        {formatMessage({id: "whyInvestContent"})}
                    </Typography>
                <GridBox>

                    {InvestmentList.map((b,index) => {
                        return (
                            <Box key={index}>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '20px',
                                        color: '#525252',
                                    }}
                                >
                                    {formatMessage({id:b.title})}

                                </Typography>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    color="text.secondary"
                                    sx={{
                                        fontWeight: 300,
                                        fontSize: '20px',
                                        color: '#525252',
                                    }}
                                >
                                    {formatMessage({id:b.content})}
                                </Typography>
                            </Box>
                        )
                    })}

                </GridBox>
                </StyledBox>
            </FlexBox>
        </IntroWrapper>
    )
}

export default WhyInvest

const IntroWrapper = styled.div`
  width: 100%;
  min-width: 100vh;
  height: 100%;
  min-height: 80vh;
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
  z-index: 4;
  
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  width: 100%;
  gap: 90px
`
const StyledBox = styled.div`
  max-width: 1280px;
`