import React, {useState} from 'react'
import styled from "@emotion/styled";
import {Button, LinearProgress, Typography} from "@mui/material";
import {H4} from "../../../../layouts/TextStyles";
import {Box} from "@mui/system";
import {useIntl} from "react-intl";
import {MyBottlesState} from "../../../../features/types";
import {myBottlesApiSlice} from "../../../../features/myBottles/myBottlesSlice";
import SellInvestmentsDialog from "./SellInvestmentsDialog";


const WineCollection = () => {
    const {formatMessage} = useIntl()

    const {
        data: data = {} as MyBottlesState, isFetching
    } = myBottlesApiSlice.useFetchWineBottlesQuery()
    const [selectedData, setSelectedData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false)
    const handleOpenDialog = (data) => {
        setSelectedData(data);
        setOpenDialog(true);
    };

    return (
        <>
            {isFetching ? (
                <LinearProgress/>
            ) : (
                <Box>
                    <H4 style={{
                        marginBottom: '16px',
                        textDecoration: 'underline'
                    }}> {formatMessage({id: "separateBottlesInvestment"})}:</H4>

                    <GridBox>
                        {data?.bottles?.map((b, index) => {
                            return (
                                <WineWrapper key={index}>
                                    <Box sx={{display: 'flex', justifyContent: "center", mb: '24px'}}>
                                        <img
                                            alt="wine"
                                            src={b.imageUrl}
                                            width={210}
                                            height={210}
                                            style={{
                                                objectFit: 'contain',
                                                maxWidth: '210px',
                                                maxHeight: '210px',
                                            }}
                                        />                                    
                                    </Box>

                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="text.secondary"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '30px',
                                            color: '#E0E0E0',
                                            mb: '8px',
                                            position: 'absolute',
                                            top: "8px",
                                            right: "16px"
                                        }}
                                    >
                                        X{b.amount}
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="text.secondary"
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '20px',
                                            color: '##525252',
                                            mb: '8px'
                                        }}
                                    >
                                        {b.title}
                                    </Typography>
                                    <FlexBox>
                                        <Typography
                                            variant="h5"
                                            component="div"
                                            color="text.secondary"
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '20px',
                                                color: '##525252',
                                            }}
                                        >
                                            {b.country.title}
                                        </Typography>
                                        <img alt="flag" src={b.country.imageUrl} width={32}/>
                                    </FlexBox>

                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="text.secondary"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '18px',
                                            color: '##525252',
                                            mb: '8px'

                                        }}
                                    >
                                        {formatMessage({id: "color"})}: <AnswerText>{b.color}</AnswerText>
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="text.secondary"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '18px',
                                            color: '##525252',
                                            mb: '8px'
                                        }}
                                    >
                                        {formatMessage({id: "wineType"})}: <AnswerText>{b.type}</AnswerText>
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="text.secondary"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '18px',
                                            color: '##525252',
                                            mb: '8px'
                                        }}
                                    >
                                        {formatMessage({id: "lastPrice"})}: <AnswerText>{b.price}</AnswerText>
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="text.secondary"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '18px',
                                            color: '##525252',
                                            mb: '8px'
                                        }}
                                    >
                                        {formatMessage({id: "volume"})}: <AnswerText>{b.volume}</AnswerText>
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="text.secondary"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '18px',
                                            color: '##525252',
                                            mb: '8px'
                                        }}
                                    >
                                        {formatMessage({id: "bottlesIncluded"})}: <AnswerText>{b.bottlesIncluded}</AnswerText>
                                    </Typography>

                                    {/*<Typography*/}
                                    {/*    variant="h5"*/}
                                    {/*    component="div"*/}
                                    {/*    color="text.secondary"*/}
                                    {/*    sx={{*/}
                                    {/*        fontWeight: 700,*/}
                                    {/*        fontSize: '18px',*/}
                                    {/*        color: '##525252',*/}
                                    {/*        mb: '8px'*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    {formatMessage({id: "lastMonthPrice"})}: <AnswerText>{b.priceChanges[0]}</AnswerText>*/}
                                    {/*    {parseInt(b.priceChanges[0]) === 0 ? '' :*/}
                                    {/*        parseInt(b.priceChanges[0]) < 0 ?*/}
                                    {/*            <img width={18} src={ArrowTop} alt="ArrowTop"/> :*/}
                                    {/*            <img width={18} src={ArrowDown} alt="ArrowDown"/>*/}
                                    {/*    }*/}
                                    {/*</Typography>*/}

                                    {/*<Typography*/}
                                    {/*    variant="h5"*/}
                                    {/*    component="div"*/}
                                    {/*    color="text.secondary"*/}
                                    {/*    sx={{*/}
                                    {/*        fontWeight: 700,*/}
                                    {/*        fontSize: '18px',*/}
                                    {/*        color: '##525252',*/}
                                    {/*        mb: '8px'*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    {formatMessage({id: "last3MonthsPrice"})}: <AnswerText>{b.priceChanges[1]}</AnswerText>*/}
                                    {/*    {parseInt(b.priceChanges[1]) === 0 ? '' :*/}
                                    {/*        parseInt(b.priceChanges[1]) < 0 ?*/}
                                    {/*            <img width={18} src={ArrowTop} alt="ArrowTop"/> :*/}
                                    {/*            <img width={18} src={ArrowDown} alt="ArrowDown"/>*/}
                                    {/*    }*/}
                                    {/*</Typography>*/}

                                    {/*<Typography*/}
                                    {/*    variant="h5"*/}
                                    {/*    component="div"*/}
                                    {/*    color="text.secondary"*/}
                                    {/*    sx={{*/}
                                    {/*        fontWeight: 700,*/}
                                    {/*        fontSize: '18px',*/}
                                    {/*        color: '##525252',*/}
                                    {/*        mb: '24px'*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    {formatMessage({id: "last12MonthsPrice"})}: <AnswerText>{b.priceChanges[2]}</AnswerText>*/}
                                    {/*    {parseInt(b.priceChanges[2]) === 0 ? '' :*/}
                                    {/*        parseInt(b.priceChanges[2]) < 0 ?*/}
                                    {/*            <img width={18} src={ArrowTop} alt="ArrowTop"/> :*/}
                                    {/*            <img width={18} src={ArrowDown} alt="ArrowDown"/>*/}
                                    {/*    }*/}
                                    {/*</Typography>*/}
                                    <Button
                                        onClick={() => handleOpenDialog(b)}
                                        sx={{fontSize: "13px"}}
                                        variant="contained"
                                        fullWidth>{formatMessage({id: "sellInvestments"})}</Button>

                                </WineWrapper>
                            )
                        })}
                    </GridBox>

                    <SellInvestmentsDialog
                        open={openDialog}
                        handleClose={() => setOpenDialog(false)}
                        data={selectedData}
                    />

                </Box>

            )}
        </>


    )
}

export default WineCollection


const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`
const WineWrapper = styled.div`
  background-color: #FFF;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px
`
const AnswerText = styled.span`
  font-size: 18px;
  font-weight: 400;
`
