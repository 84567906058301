import React from 'react'
import Navbar from "../../../components/navbar/NavBar";
import styled from "@emotion/styled";
import {H4} from "../../../layouts/TextStyles";
import TradeHistoryTable from "./components/TradeHistoryTable";
import {useIntl} from "react-intl";
import Footer from "../Footer/Footer";

const TradeHistory = () => {
    const {formatMessage} = useIntl()

    return (
        <Wrapper>
            <Navbar/>
            <Main>
                <H4 style={{marginBottom: '16px', textDecoration: 'underline'}}>{formatMessage({id: "myDealsAndTradeHistory"})}</H4>
                <TradeHistoryTable/>
            </Main>
            <Footer/>
        </Wrapper>

    )
}

export default TradeHistory

const Wrapper = styled.div`
  background-color: #F1F3F6;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Main = styled.main`
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px 40px;
`

