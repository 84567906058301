import Team from "../assets/img/Landing/Intro/team.png"
import Selection from "../assets/img/Landing/Intro/selection.png"
import Security from "../assets/img/Landing/Intro/security.png"
import Oportunity from "../assets/img/Landing/Intro/oportunity.png"
import Research from "../assets/img/Landing/Intro/research.png"
import Liquidity from "../assets/img/Landing/Intro/liquidity.png"
import Medal1 from "../assets/img/Landing/Intro/medal1.png"
import Medal2 from "../assets/img/Landing/Intro/medal2.png"
import Medal3 from "../assets/img/Landing/Intro/medal3.png"
import Stars from "../assets/img/Landing/Intro/stars.png"
import FourStars from "../assets/img/Landing/Intro/fourStars.png"
import Sommelier from "../assets/img/somielers-fund.png"
import Bordeaux from "../assets/img/bordeaux-fund.png"
import UKFlag from "../assets/img/united-kingdom-flag.png"
import InvestmentArrowDown from "../assets/img/arrow-down-investment.png"
import InvestmentArrowTop from "../assets/img/arrow-top-investment.png"
import Wine1 from "../assets/img/wine1.png"
import Wine2 from "../assets/img/wine2.png"
import Wine3 from "../assets/img/wine3.png"
import Wine4 from "../assets/img/wine4.png"
import FirstCustomerReviewer from '../assets/img/Landing/Intro/customerFirstForReview.jpeg'
import SecondCustomerReviewer from '../assets/img/Landing/Intro/customerSecondForReview.jpeg'
import ThirdCustomerReviewer from '../assets/img/Landing/Intro/customerThirdForReview.jpeg'

export const Lorum =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc maecenas dignissim non ultrices turpis felis. Pulvinar odio in lobortis semper tellus pharetra, aenean odio sed. Tempor, sit risus pretium pretium, eleifend enim. Amet eget sem volutpat tortor mattis ut tincidunt est. Urna volutpat semper morbi neque consectetur aliquet tellus. Egestas id etiam mi lacus in hendrerit scelerisque sed. Enim volutpat sem mattis pretium. Tristique morbi imperdiet vitae volutpat viverra in. Sed at lobortis sagittis, ullamcorper platea dictum curabitur viverra sit.'

export const REACT_APP_IAM_SERVICE_BASE_URL =
    'https://bf-vinadeis.com/api'

export const REACT_APP_STUDENT_COURSE_BASE_URL =
    'https://educon-online-student-course-service.azurewebsites.net/api'

export const ALPHABET = [
    {id: '1', name: 'A'},
    {id: '2', name: 'B'},
    {id: '3', name: 'C'},
    {id: '4', name: 'D'},
    {id: '5', name: 'E'},
    {id: '6', name: 'F'},
    {id: '7', name: 'G'},
    {id: '8', name: 'H'},
    {id: '9', name: 'I'},
    {id: '10', name: 'J'},
    {id: '11', name: 'K'},
    {id: '12', name: 'L'},
    {id: '13', name: 'M'},
    {id: '14', name: 'N'},
    {id: '15', name: 'O'},
    {id: '16', name: 'P'},
    {id: '17', name: 'Q'},
    {id: '18', name: 'R'},
    {id: '19', name: 'S'},
    {id: '20', name: 'T'},
    {id: '21', name: 'U'},
    {id: '22', name: 'V'},
    {id: '23', name: 'W'},
    {id: '24', name: 'X'},
    {id: '25', name: 'Y'},
    {id: '26', name: 'Z'},
]

export const MODERATOR = 'MODERATOR'

export const CURATOR = 'CURATOR'

export const TEACHER = 'TEACHER'

export const SUPER_ADMIN = 'SUPER_ADMIN'

export const STUDENT = 'STUDENT'

export const MoreBenefitsContent = [
    {
        title: "moreBenefitsContentTitle1",
        content: "moreBenefitsContentDescription1"
    },
    {
        title: "moreBenefitsContentTitle2",
        content: "moreBenefitsContentDescription2"
    },
    {
        title: "moreBenefitsContentTitle3",
        content: "moreBenefitsContentDescription3"
    },
    {
        title: "moreBenefitsContentTitle4",
        content: "moreBenefitsContentDescription4"
    },
    {
        title: "moreBenefitsContentTitle5",
        content: "moreBenefitsContentDescription5"
    },
    {
        title: "moreBenefitsContentTitle6",
        content: "moreBenefitsContentDescription6"
    },

]

export const InvestmentList = [
    {
        title: 'investmentListTitle1',
        content: 'investmentListContent1'
    },
    {
        title: 'investmentListTitle2',
        content: 'investmentListContent2'
    },
    {
        title: 'investmentListTitle3',
        content: 'investmentListContent3'
    },

]
export const KeyAdvantagesList = [
    {
        title: 'keyAdvantagesListTitle1',
        content: 'keyAdvantagesListContent1',
    },
    {
        title: 'keyAdvantagesListTitle2',
        content: 'keyAdvantagesListContent2',
    },
    {
        title: 'keyAdvantagesListTitle3',
        content: 'keyAdvantagesListContent3',
    },
    {
        title: 'keyAdvantagesListTitle4',
        content: 'keyAdvantagesListContent4',
        subtitle: "keyAdvantagesListSubtitle"
    },

]


export const BenefitsContent = [
    {
        img: Selection,
        title: "benefitTitle1",
        content: "benefitContent1"
    },
    {
        img: Security,
        title: "benefitTitle2",
        content: "benefitContent2"
    },
    {
        img: Team,
        title: "benefitTitle3",
        content: "benefitContent3"
    },
    {
        img: Oportunity,
        title: "benefitTitle4",
        content: "benefitContent4"
    },
    {
        img: Research,
        title: "benefitTitle5",
        content: "benefitContent5"
    },
    {
        img: Liquidity,
        title: "benefitTitle6",
        content: "benefitContent6"
    },

]

export const FooterContentText = [
    {
        title: "account",
    },
    {
        title: "trading",
    },
    {
        title: "portfolio",
    },
    {
        title: "analytics",
    },
    {
        title: "support",
    },

]

export const LandingNavBarContentText = [
    {
        title: "aboutWineInvesting",
    },
    {
        title: "howItWorks",
    },
    {
        title: "clientsReviews",
    },
    {
        title: "benefits",
    },
    {
        title: "contact",
    },
]
export const WhyUsContentText = [
    {
        title: "whyUsTitle1",
        content: "whyUsContent1"
    }, {
        title: "whyUsTitle2",
        content: "whyUsContent2"
    }, {
        title: "whyUsTitle3",
        content: "whyUsContent3"
    }, {
        title: "whyUsTitle4",
        content: "whyUsContent4"
    },
]

export const HowItWorksContentText = [
    {
        title: "1",
        content: "howTtWorksContentText1"
    },
    {
        title: "2",
        content: "howTtWorksContentText2"
    },
    {
        title: "3",
        content: "howTtWorksContentText3"
    },

]
export const CustomerFeedbackContentText = [
    {
        img: FirstCustomerReviewer,
        rating: FourStars,
        title: "customerReviewsContentTitle1",
        customerName: "customerReviewsContentCustomerName1"
    },
    {
        img: SecondCustomerReviewer,
        rating: Stars,
        title: "customerReviewsContentTitle2",
        customerName: "customerReviewsContentCustomerName2"
    },
    {
        img: ThirdCustomerReviewer,
        rating: FourStars,
        title: "customerReviewsContentTitle3",
        customerName: "customerReviewsContentCustomerName3"
    },

]
export const NavBarContentText = [
    {
        title: "account",
        link: "/account"
    },
    {
        title: "trading",
        link: "/trading"

    },
    {
        title: "portfolio",
        link: "/portfolio"

    },
    {
        title: "tradeHistory",
        link: "/trade-history"

    },
    {
        title: "balanceHistory",
        link: "/balance-history"

    },
    {
        title: "support",
        link: "/support"

    },

]


export const FooterLinkText = [
    {
        title: "termsAndConditions",
    },
    {
        title: "cookiePolicy",
    },
]
export const FooterImg = [
    {
        img: Medal1
    },
    {
        img: Medal2
    },
    {
        img: Medal3
    },
]
export const WineAccountDescriptionText = [
    {
        desc: "wineMarketNewsContent1"
    },
    {
        desc: "wineMarketNewsContent2"
    },
]

export const fakeDealsAndHistoryDate = [
    {
        id: 1,
        dealType: 'sell',
        product: '2021 Chateau La Mission Haut-Brion Cru Classe',
        quantity: '1',
        price: '1830€',
        completeDate: '01.05.2024',
        totalAmount: '1830 €',
        status: 'Waiting approval'
    },
    {
        id: 1,
        dealType: 'buy',
        product: '2021 Chateau La Mission Haut-Brion Cru Classe',
        quantity: '1',
        price: '1076€',
        completeDate: '01.05.2024',
        totalAmount: '1076 €',
        status: 'Success'

    },
    {
        id: 1,
        dealType: 'buy',
        product: "Sommelier's fund shares",
        quantity: '20',
        price: '250€',
        completeDate: '01.05.2024',
        totalAmount: '5000 €',
        status: 'Canceled'
    },
]

export const fakeBalanceData = [
    {
        id: 1,
        transactionType: 'Deposit',
        amount: '1830€',
        date: '01.05.2024',
        status: 'Waiting approval'
    },
    {
        id: 2,
        transactionType: 'Withdrawal',
        amount: '1076€',
        date: '01.05.2024',
        status: 'Waiting approval'
    },
    {
        id: 3,
        transactionType: 'Deposit',
        amount: '250€',
        date: '01.05.2024',
        status: 'Waiting approval'
    },
]

export const SupportQuestionsAndAnswers = [
    {
        question: "supportFirstQuestion",
        answer: "supportFirstAnswer",
    },
    {
        question: "supportSecondQuestion",
        answer: "supportFirstAnswer",
    },
    {
        question: "supportThirdQuestion",
        answer: "supportFirstAnswer",
    },

]

export const TradingMainInvestmentInfo = [
    {
        title: "tradingContentTitle1",
        desc1: "tradingContentDescriptionTitle",
        desc2:
            [
                {
                    title: "tradingContentDescriptionText1"
                },
                {
                    title: "tradingContentDescriptionText2"
                },
                {
                    title: "tradingContentDescriptionText3"
                }
            ],
        img: Sommelier
    },
    {
        title: "tradingContentTitle2",
        desc1: "tradingContentDescriptionTitle",
        desc2:
            [
                {
                    title: "tradingContentDescriptionText1"
                },
                {
                    title: "tradingContentDescriptionText2"
                },
                {
                    title: "tradingContentDescriptionText3"
                }
            ],
        img: Bordeaux
    },
]

export const WineCollectionInMarket = [
    {
        title: "2020 Chateau La Mission Haut-Brion Cru Classe",
        city: "United Kingdom",
        flag: UKFlag,
        arrowTop: InvestmentArrowTop,
        arrowDown: InvestmentArrowDown,
        wine: Wine1,
        count: 'X2'
    },
    {
        title: "2020 Chateau La Mission Haut-Brion Cru Classe",
        city: "United Kingdom",
        flag: UKFlag,
        arrowTop: InvestmentArrowTop,
        arrowDown: InvestmentArrowDown,
        wine: Wine2
    },
    {
        title: "2020 Chateau La Mission Haut-Brion Cru Classe",
        city: "United Kingdom",
        flag: UKFlag,
        arrowTop: InvestmentArrowTop,
        arrowDown: InvestmentArrowDown,
        wine: Wine3,
        count: 'X3'

    },
    {
        title: "2020 Chateau La Mission Haut-Brion Cru Classe",
        city: "United Kingdom",
        flag: UKFlag,
        arrowTop: InvestmentArrowTop,
        arrowDown: InvestmentArrowDown,
        wine: Wine4
    },
    {
        title: "2020 Chateau La Mission Haut-Brion Cru Classe",
        city: "United Kingdom",
        flag: UKFlag,
        arrowTop: InvestmentArrowTop,
        arrowDown: InvestmentArrowDown,
        wine: Wine1
    },
    {
        title: "2020 Chateau La Mission Haut-Brion Cru Classe",
        city: "United Kingdom",
        flag: UKFlag,
        arrowTop: InvestmentArrowTop,
        arrowDown: InvestmentArrowDown,
        wine: Wine2
    },
    {
        title: "2020 Chateau La Mission Haut-Brion Cru Classe",
        city: "United Kingdom",
        flag: UKFlag,
        arrowTop: InvestmentArrowTop,
        arrowDown: InvestmentArrowDown,
        wine: Wine3
    },
    {
        title: "2020 Chateau La Mission Haut-Brion Cru Classe",
        city: "United Kingdom",
        flag: UKFlag,
        arrowTop: InvestmentArrowTop,
        arrowDown: InvestmentArrowDown,
        wine: Wine4
    },

]

export const YourPortfolioOfWine = [
    {
        title: "totalIntheFunds",
        amount1: "20,340€ ",
    },
    {
        title: "bordeauxFund",
        amount2: "0,000€",
        stocks: "0 Stocks"
    },
    {
        title: "sommeliersFund",
        amount2: "1,979€ ",
        stocks: "4 Stocks"
    },
    {
        title: "totalInTheBottles",
        amount1: "11,923€ ",
    },
    {
        title: "unInvestedCash",
        amount1: "11,923€ ",
    },
    {
        title: "totalAmountOfBottles",
        amount1: "11,923€ ",
    },


]