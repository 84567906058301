import React, { useState, useEffect, Suspense } from 'react';
import styled from "@emotion/styled";
import LandingNavBar from '../../components/landingNavbar';
import Loader from "../../components/loader/Loader";
import IntroComponent from "./Intro/Intro";
import WhyUsComponent from "./WhyUs/WhyUs";
import HowItWorksComponent from "./HowItWorks/HowItWorks";
import CustomerFeedbackComponent from "./CustomersFeedback/CustomerFeedback";
import BenefitsComponent from "./Benefits/Benefits";
import InvestmentComponent from "./Investment/Investment";
import KeyAdvantagesComponent from "./KeyAdvantages/KeyAdvantages";
import LandingFooterComponent from "./LandingFooter/LandingFooter";
import InvestmentAdvertisementComponent from "./InvestmentAdvertisement/MoreBenefits";
import WhyInvestComponent from "./WhyInvest/WhyInvest";
import withIntersectionObserver from './withIntersectionObserver';

const Intro = withIntersectionObserver(IntroComponent, 'fade-in');
const WhyUs = withIntersectionObserver(WhyUsComponent, 'fade-in');
const HowItWorks = withIntersectionObserver(HowItWorksComponent, 'fade-in');
const CustomerFeedback = withIntersectionObserver(CustomerFeedbackComponent, 'fade-in');
const Benefits = withIntersectionObserver(BenefitsComponent, 'fade-in');
const Investment = withIntersectionObserver(InvestmentComponent, 'fade-in');
const WhyInvest = withIntersectionObserver(WhyInvestComponent, 'fade-in');
const InvestmentAdvertisement = withIntersectionObserver(InvestmentAdvertisementComponent, 'fade-in');
const KeyAdvantages = withIntersectionObserver(KeyAdvantagesComponent, 'fade-in');
const LandingFooter = withIntersectionObserver(LandingFooterComponent, 'fade-in');

const Home = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Wrapper>
            {loading ? (
                <Loader />
            ) : (
                <Suspense fallback={<Loader />}>
                    <LandingNavBar />
                    <Main>
                        <Intro />
                        <WhyUs />
                        <HowItWorks />
                        <CustomerFeedback />
                        <Benefits />
                        <Investment />
                        <WhyInvest />
                        <InvestmentAdvertisement />
                        <KeyAdvantages />
                        <LandingFooter />
                    </Main>
                </Suspense>
            )}
        </Wrapper>
    );
};

export default Home;

const Main = styled.main`
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  background-color: #fff;
  min-height: 100vh;
  height: 100%;
`;
