import React, {useState} from 'react'
import styled from '@emotion/styled'
import Logo from '../../assets/img/Logo.png'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Typography from "@mui/material/Typography";
import {NavBarContentText} from "../../utils/constants";
import LanguageMenu from "../muiComponents/languageMenu";
import {useIntl} from "react-intl";
import WithdrawalAndAddFundsDialog from "../../pages/user/Account/components/WithdrawalAndAddFundsDialog";
import {logout} from "../../features/user/userSlice";
import {useDispatch} from "react-redux";

const NavBar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {formatMessage} = useIntl()
    const [openDialogSecond, setOpenDialogSecond] = useState(false)
    const [selectedDialogTitle, setSelectedDialogTitle] = useState('');

    const handleOpenDialogSecond = (title) => {
        setSelectedDialogTitle(title);
        setOpenDialogSecond(true);
    };

    const handleLogout = () => {
        dispatch(logout()); // Dispatch the logout action
        navigate('/'); // Redirect to the home page
    };


    return (
        <Wrapper>
            <Container>
                <Link to="/" onClick={handleLogout}>
                    <img width={118} height={68} src={Logo} alt={'logo'} />
                </Link>
                <FlexBox>
                    {NavBarContentText.map(b => {
                        return(
                            <Typography
                                key={b.title}
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                onClick={() => navigate(b.link)}
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '15px',
                                    color: (theme) => theme.palette.grey[900],
                                    cursor: "pointer"
                                }}
                            >
                                {formatMessage({id: b.title})}
                            </Typography>
                        )
                    })}
                    <LanguageMenu/>

                </FlexBox>

                    <Button
                        size="medium"
                        variant="contained"
                        onClick={() => handleOpenDialogSecond('Add Funds')}
                    >
                        {formatMessage({id: "addFunds"})}
                    </Button>
                <WithdrawalAndAddFundsDialog
                    open={openDialogSecond}
                    handleClose={() => setOpenDialogSecond(false)}
                    title={selectedDialogTitle}
                  />

            </Container>
        </Wrapper>
    )
}

export default NavBar

const Wrapper = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    background: white;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
  box-shadow: #929292 0 0 30px;

    @media screen and (max-width: 500px) {
        display: none;
    }
`

const Container = styled.div`
    max-width: 1340px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1380px) {
        padding: 0 20px;
    }
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 65px;
`

